// App Base
import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContextProvider'
import ServicesContextProvider from '../contexts/ServicesContextProvider'
import UsersContextProvider from '../contexts/UsersContextProvider'
import Dashboard from './Dashboard'
import ForVerification from './ForVerification'
import ForVerificationGym from './ForVerificationGym'
import IncidentReports from './IncidentReports'
import LoginPage from './Login'
import SignUp from './SignUp'
import Trainees from './Trainees'
import Classes from './Classes'
import Emails from './Emails'
import Subscriptions from './Subscriptions'
import Sessions from './Sessions'
import Payments from './Payments'
import Refunds from './Refunds'
import Notifications from './Notifications'

const hasToken = localStorage.getItem('token') !== null

const App = () => {
    const { currentUser } = useContext(AuthContext)

    const protectedRoutes = () => {
        return (
            <Route>
                <Route path="/dashboard">
                    <UsersContextProvider>
                        <Dashboard/>
                    </UsersContextProvider>
                </Route>
                <Route path="/for-verification">
                    <UsersContextProvider>
                        <ForVerification/>
                    </UsersContextProvider>
                </Route>
                <Route path="/for-verification-gym">
                    <ForVerificationGym/>
                </Route>
                <Route path="/trainees">
                    <UsersContextProvider>
                        <Trainees/>
                    </UsersContextProvider>
                </Route>
                <Route path="/classes">
                    <UsersContextProvider>
                        <Classes/>
                    </UsersContextProvider>
                </Route>
                <Route path="/email">
                    <UsersContextProvider>
                        <Emails/>
                    </UsersContextProvider>
                </Route>
                <Route path="/subscriptions">
                    <ServicesContextProvider>
                        <Subscriptions/>
                    </ServicesContextProvider>
                </Route>
                <Route path="/sessions">
                    <ServicesContextProvider>
                        <Sessions/>
                    </ServicesContextProvider>
                </Route>
                <Route path="/payments">
                    <UsersContextProvider>
                        <Payments/>
                    </UsersContextProvider>
                </Route>
                <Route path="/refunds">
                    <UsersContextProvider>
                        <Refunds/>
                    </UsersContextProvider>
                </Route>
                <Route path="/incident-reports">
                    <ServicesContextProvider>
                        <IncidentReports/>
                    </ServicesContextProvider>
                </Route>
                <Route path="/notifications">
                    <UsersContextProvider>
                        <Notifications/>
                    </UsersContextProvider>
                </Route>
            </Route>
        )
    }

    let isLogin = currentUser ?? hasToken

    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <LoginPage/>
                </Route>
                <Route path="/sign-up" exact>
                    <SignUp/>
                </Route>
                {
                    isLogin ? protectedRoutes() :
                        <Route path="/" exact>
                            <LoginPage/>
                        </Route>
                }
            </Switch>
        </Router>
    )
}

export default App
