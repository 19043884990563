import axios from "axios";
import { db } from "./Firebase";
import { epochToDate } from "./Helper";

// export const API_URL = "https://test.housefit.org";
export const API_URL = "https://api.housefit.org";

export const getSubscriptions = async () => {
    // let subscriptions = [];
    //  await db.collection("subscriptions").get().then(
    //     function(querySnapshot) {
    //         querySnapshot.forEach(function(doc) {
    //                 let subscriptionsObj = {
    //                     'id' : doc.id,
    //                     'traineeName': doc.data().traineeEmail,
    //                     'trainerName':doc.data().ptEmail,
    //                     'createdDate': epochToDate(doc.data().creationTs.seconds),
    //                     'expiryDate': epochToDate(doc.data().expirationTs.seconds)
    //                 };
    //                 subscriptions.push(subscriptionsObj);
    //         });
    //     });
    // return subscriptions;

    return db
        .collection("subscriptions")
        .orderBy("creationTs", "desc")
        .get()
        .then(function (querySnapshot) {
            let subscriptions = [];
            querySnapshot.forEach(function (doc) {
                subscriptions.push({
                    id: doc.id,
                    traineeName: doc.data().traineeEmail,
                    trainerName: doc.data().ptEmail,
                    createdDate: epochToDate(doc.data().creationTs.seconds),
                    expiryDate: epochToDate(doc.data().expirationTs.seconds),
                });
            });

            return subscriptions;
        });
};

export const getSessions = async () => {
    return db
        .collection("sessions")
        .get()
        .then(function (querySnapshot) {
            let sessions = [];
            querySnapshot.forEach(function (doc) {
                sessions.push({
                    id: doc.id,
                    ptName: doc.data().ptName,
                    rate: doc.data().rate,
                    sessionStart: epochToDate(doc.data().sessionStart.seconds),
                    sessionEnd: epochToDate(doc.data().sessionEnd.seconds),
                    status: doc.data().status,
                    traineeName: doc.data().traineeName,
                });
            });

            return sessions;
        });
};

export const getReports = () => {
    // let usersRef = db.collection('users');
    return db
        .collection("reports")
        .get()
        .then(function (querySnapshot) {
            let reports = [];
            querySnapshot.forEach(function (doc) {
                let reportObj = {
                    id: doc.id,
                    attachment: doc.data().attachment,
                    details: doc.data().reason,
                    ptName: doc.data().ptEmail,
                    reportDate: epochToDate(doc.data().creationTs.seconds),
                    traineeName: doc.data().traineeEmail,
                    ptId: doc.data().ptId,
                    status: doc.data().status ?? "not-disabled",
                };

                // let userSnapshot = usersRef.doc(reportObj.ptId).get();

                // reportObj.status = userSnapshot.data().ptInfo.reported === true ? 'reported' : 'active';
                reports.push(reportObj);
            });

            return reports;
        });
};

const handleDisablePT = async (userId) => {
    let token = await localStorage.getItem("token");
    console.log(token);

    await axios.post(
        API_URL + "/users/disable/" + userId,
        {},
        {
            headers: {
                Accept: "application/x-www-form-urlencoded",
                "X-HouseFit-Authorization": token,
            },
        }
    );
};

const handleEmailNotification = async (userId) => {
    let token = await localStorage.getItem("token");
    console.log(token);

    let email = await axios.post(
        API_URL + "/email/send",
        {
            id: userId,
            code: "PT_REVOCATION",
        },

        {
            headers: {
                Accept: "application/x-www-form-urlencoded",
                "X-HouseFit-Authorization": token,
            },
        }
    );

    email.then((response) => {
        console.log("This is an email response", response);
    });
};

const handleUpdateSessions = async (subscriptionId, sessionId) => {
    let session = db
        .collection("subscriptions/" + subscriptionId + "/sessions")
        .doc(sessionId);
    session.update({
        status: "cancelled",
    });

    let subscription = db.collection("subscriptions").doc(subscriptionId);
    subscription.update({
        status: "cancelled",
    });
};

export const updateUserInfo = async (reportId, ptId, status) => {
    let sessions = [];
    let userRef = await db.collection("users").doc(ptId);
    let ptSessions = await db
        .collectionGroup("sessions")
        .where("ptId", "==", ptId)
        .where("status", "in", ["open", "scheduled"])
        .orderBy("targetStartTs")
        .get();

    ptSessions.forEach(function (sesh) {
        sessions.push({
            id: sesh.id,
            subscriptionId: sesh.data().subscriptionId,
        });
    });

    sessions.forEach(function (sesh) {
        handleUpdateSessions(sesh.subscriptionId, sesh.id);
    });

    console.log(sessions);

    let reportRef = await db.collection("reports").doc(reportId);

    userRef.update({
        "ptInfo.reported": true,
    });

    return userRef.get().then(function (doc) {
        if (doc.exists === false) {
            return false;
        }

        handleEmailNotification(ptId);
        handleDisablePT(ptId);

        reportRef
            .update({
                status: "disabled",
            })
            .catch((err) => {
                return err;
            });

        return true;
    });
};
