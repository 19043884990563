import React, { useContext, useState } from 'react'
import 'fontsource-roboto'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { UsersContext } from '../../contexts/UsersContextProvider'
import { ModalContext } from '../../contexts/ModalContext1'

import { Button } from '@material-ui/core'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const RefundsTableComponent = () => {
    const { verifiedTrainers1 } = useContext(UsersContext)
    const [searchTerm, setSearchTerm] = useState('')
    const {
        setIsOpen,
        setModalData
    } = useContext(ModalContext)

    return (
        <TableContainer component={Paper}>
            <form style={{
                paddingBottom: '10px',
            }}>
                <label> Search by Email: </label>
                <input
                    icon="search"
                    placeholder="Keyword"
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}
                />
            </form>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>PT EMAIL</StyledTableCell>
                        <StyledTableCell>TRAINEE</StyledTableCell>
                        <StyledTableCell>STATUS</StyledTableCell>
                        <StyledTableCell>PAYMENT STATUS</StyledTableCell>
                        <StyledTableCell>SUBSCRIPTION ID</StyledTableCell>
                        <StyledTableCell>RATE PER SESSION</StyledTableCell>
                        <StyledTableCell>TOTAL REFUND AMOUNT</StyledTableCell>
                        <StyledTableCell>NUMBER OF CANCELLED SESSION</StyledTableCell>
                        <StyledTableCell>ACTION</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {verifiedTrainers1 ? verifiedTrainers1.filter((val) => {
                        if (searchTerm === '') {
                            return true
                        } else if (val.trainee.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        } else if (val.fullName.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                        return false
                    }).map((session) => (
                        <StyledTableRow key={session.id}>
                            <StyledTableCell>
                                {session.fullName}
                            </StyledTableCell>
                            <StyledTableCell>{session.trainee}</StyledTableCell>
                            <StyledTableCell>{session.status}</StyledTableCell>
                            <StyledTableCell>{session.paidStatus}</StyledTableCell>
                            <StyledTableCell>{session.subscriptionId}</StyledTableCell>
                            <StyledTableCell>{session.rate}</StyledTableCell>
                            <StyledTableCell>{session.totalRefund}</StyledTableCell>
                            <StyledTableCell>{session.numberOfSessions}</StyledTableCell>
                            <StyledTableCell>
                                {
                                    session.paidStatus ?
                                        <Button variant="contained" color="primary"
                                                disabled={session.paidStatus === 'PAID'}
                                                onClick={() => {
                                                    setIsOpen(true)
                                                    setModalData({
                                                        'traineeEmail': session.trainee,
                                                        'ptEmail': session.fullName,
                                                        'sessionId': session.id,
                                                        'subscriptionId': session.subscriptionId,
                                                        'totalRefund': session.totalRefund
                                                    })
                                                }}> REFUND </Button> : '...Loading'
                                }
                            </StyledTableCell>
                        </StyledTableRow>
                    )) : ''}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RefundsTableComponent
