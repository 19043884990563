import React, {createContext, useState } from "react";

export const ModalContext = createContext({});

const ModalContextProvider = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    return (
        <ModalContext.Provider value={{
            isOpen,
            modalData,
            setIsOpen,
            setModalData
          }}>
          {props.children}
        </ModalContext.Provider>
      );
};

export default ModalContextProvider;