import React from 'react'
import 'fontsource-roboto';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography'
import SubscriptionsTableComponent from '../table/SubscriptionsTable'

const Div = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
`;

const SubscriptionsContentComponent = () => {
    return (
        <Div>
            <Typography variant="h6" component="div">
                SUBSCRIPTIONS
            </Typography>
            <SubscriptionsTableComponent />
        </Div>    
    );
}

export default SubscriptionsContentComponent;