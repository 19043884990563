import React, {useContext, useState} from 'react'

import 'fontsource-roboto';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

import {ServicesContext} from '../../contexts/ServicesContextProvider';
import {updateUserInfo} from '../../services/Service';


import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const IncidentReportsTable = () => {
  const {reports, getReports, setReports} = useContext(ServicesContext);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [searchTerm, setSearchTerm] = useState('');



  const handleEnableTrainer = (reportId, ptId, status) => {
    updateUserInfo(reportId, ptId, status).then((response) => {
      setOpen(true);

      if (response === true) {
        setSeverity('success');
        setError('Trainer has been disabled.');
        // window.location.reload(false);

          getReports().then(response =>{
            setReports(response);
        });
        setTimeout(setOpen(false), 1000);
        return;
      }

      setSeverity('error');
      setError('Something went wrong');

      setTimeout(setOpen(false), 1000);
    }).catch(errorResponse => {
      console.log('error updating', errorResponse);

      setSeverity('error');
      setError('Something went wrong');

      setTimeout(setOpen(false), 1000);
    });
  }

  const tableRow = () => {
    return reports.map((row) => (
        <StyledTableRow key={row.id}>
            <StyledTableCell>
                {row.status !== 'disabled' ? row.status : 'disabled'}
            </StyledTableCell>
            <StyledTableCell>{row.ptName}</StyledTableCell>
            <StyledTableCell>{row.traineeName}</StyledTableCell>
            <StyledTableCell>{row.reportDate}</StyledTableCell>
            <StyledTableCell>{row.details}</StyledTableCell>
            <StyledTableCell>{row.attachment}</StyledTableCell>
            <StyledTableCell>
              {
                row.status ? <Button 
                variant="contained" 
                color={'secondary'}
                className="disableButton"
                disabled={row.status === 'disabled'}
                // onClick = { () => handleEnableTrainer(row.id, row.ptId, true)}> 
                onClick={() => {if(window.confirm('Are you sure you want to disable this PT?')){handleEnableTrainer(row.id, row.ptId, true)};}}
                > Disable 
                </Button> : '...Loading'
              }
            </StyledTableCell> 
        </StyledTableRow>
    ));
  }
  
    return (
        <TableContainer component={Paper}>
          <form style = {{
                      paddingBottom : '10px',
                    }}>
              <label> Search by Email: </label>
              <input 
                    icon='search' 
                    placeholder='Keyword' 
                    onChange={(event) => {
                      setSearchTerm(event.target.value); 
                    }}
                />
            </form>
            <Table aria-label="customized table">
                  <Snackbar open={open} autoHideDuration={3000}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                      <Alert severity={severity}>
                      {error}
                      </Alert>
                  </Snackbar>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>PT STATUS</StyledTableCell>
                        <StyledTableCell>PT REPORTED</StyledTableCell>
                        <StyledTableCell>TRAINEE</StyledTableCell>
                        <StyledTableCell>REPORT DATE</StyledTableCell>
                        <StyledTableCell>REPORT DETAILS</StyledTableCell>
                        <StyledTableCell>ATTACHMENT</StyledTableCell>
                        <StyledTableCell>ACTION</StyledTableCell>
                    </TableRow>
                </TableHead>

                {/* <TableBody> 
                    {
                      reports.length > 0 ? tableRow() : <TableRow></TableRow>
                    }
                </TableBody> */}
                <TableBody>
                {reports.filter((val) => {
                      if (searchTerm === "") {
                        return true
                      } else if (val.ptName.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return true
                      } else if (val.traineeName.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return true
                      }
                      return false;
                    }).map((row) => (
                        <StyledTableRow key={row.id}>
                        <StyledTableCell>
                            {row.status !== 'disabled' ? row.status : 'disabled'}
                        </StyledTableCell>
                        <StyledTableCell>{row.ptName}</StyledTableCell>
                        <StyledTableCell>{row.traineeName}</StyledTableCell>
                        <StyledTableCell>{row.reportDate}</StyledTableCell>
                        <StyledTableCell>{row.details}</StyledTableCell>
                        <StyledTableCell>{row.attachment}</StyledTableCell>
                        <StyledTableCell>
                          {
                            row.status ? <Button 
                            variant="contained" 
                            color={'secondary'}
                            className="disableButton"
                            disabled={row.status === 'disabled'}
                            // onClick = { () => handleEnableTrainer(row.id, row.ptId, true)}
                            onClick={() => {if(window.confirm('Are you sure you want to disable this PT?')){handleEnableTrainer(row.id, row.ptId, true)};}}
                            > Disable 
                            </Button> : '...Loading'
                          }
                        </StyledTableCell> 
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default IncidentReportsTable;
