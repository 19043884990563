import { auth, db } from './Firebase'
import { epochToDate } from './Helper'
import axios from 'axios'
import { API_URL } from './Service'

export const getClasses = async () => {
    const users = []
    const classList = []

    const parseDate = (date) => new Date(date?.seconds * 1000 + date?.nanoseconds / 1000000)

    db.collection('users')
        .get()
        .then((usersSnapshot) => {
            usersSnapshot.forEach((doc) => {
                const {
                    name,
                    lastName,
                    email,
                    countryCode
                } = doc.data()

                users.push({
                    id: doc.id,
                    name,
                    lastName,
                    email,
                    countryCode
                })
            })
        })
        .catch((err) => {
            console.log({ err })
        })

    db.collectionGroup('classes')
        .get()
        .then((classesSnapshot) => {
            classesSnapshot.forEach((doc) => {
                const traineeMap = []
                const sessionMap = []

                const {
                    creationTs,
                    expirationTs,
                    ptId,
                    rate,
                    sessionCount,
                    status,
                    totalAmount,
                    traineeId,
                } = doc.data()

                doc.ref.collection('trainees')
                    .get()
                    .then((traineeSnapshot) => {
                        traineeSnapshot.docs.forEach((docx) => {
                            const {
                                traineeId,
                                email,
                            } = docx.data()

                            traineeMap.push({
                                traineeId,
                                email,
                            })
                        })
                    })
                    .catch((err) => {
                        console({ err })
                    })

                doc.ref.collection('sessions')
                    .get()
                    .then((sessionSnapshot) => {
                        sessionSnapshot.docs.forEach((docx) => {
                            const {
                                isRated,
                                actualEndTs,
                                actualStartTs,
                                targetStartTs,
                                targetEndTs,
                                comment,
                                paid,
                                ptEmail,
                                ptId,
                                ptInCall,
                                rate,
                                traineeEmail,
                                status
                            } = docx.data()

                            sessionMap.push({
                                isRated,
                                actualEndTs: parseDate(actualEndTs),
                                actualStartTs: parseDate(actualStartTs),
                                targetEndTs: parseDate(targetEndTs),
                                targetStartTs: parseDate(targetStartTs),
                                comment,
                                paid,
                                ptEmail,
                                ptId,
                                ptInCall,
                                rate,
                                traineeEmail,
                                traineeId,
                                status
                            })
                        })
                    })
                    .catch((err) => {
                        console.error({ err });
                    })

                classList.push({
                    id: doc.id,
                    ptDetails: users.filter((user) => user.id === ptId).pop(),
                    creationTs: parseDate(creationTs),
                    expirationTs: parseDate(expirationTs),
                    ptId,
                    traineeId,
                    rate,
                    sessionCount,
                    status,
                    totalAmount,
                    traineeDetails: users.filter((user) => user.id === traineeId),
                    sessions: sessionMap
                })
            })
        })
        .catch((err) => {
            console.log({ err })
        })

    return classList.sort((a, b) => new Date(a.creationTs) - new Date(b.creationTs))
}

export const getAllUsers = async () => {
    try {
        const snapshot = await db.collection('users').get()
        return snapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
        })
    } catch (err) {
        console.error('Error fetching users:', err)
        return []
    }
}

export const getPersonalTrainers = async () => {
    return db
        .collection('users')
        .where('userType', '==', 'pt')
        .get()
        .then(function (querySnapshot) {
            let users = []
            querySnapshot.forEach(function (doc) {
                users.push({
                    id: doc.id,
                    firstName: doc.data().firstName,
                    lastName: doc.data().lastName,
                    middleName: doc.data().middleName,
                    gender: doc.data().gender ? doc.data().gender : 'none',
                    status:
                        doc.data().ptInfo.reported === false
                            ? doc.data().ptInfo.status
                            : 'reported',
                    awardsAndExperiences: doc.data().ptInfo
                        ? doc.data().ptInfo.awardsAndExperiences
                        : 'none',
                    downloadablesCV: doc.data().ptInfo
                        ? doc.data().ptInfo.cv
                        : 'none',
                    downloadablesCERT: doc.data().ptInfo
                        ? doc.data().ptInfo.certificates
                        : 'none',
                    email: doc.data().email,
                })
            })

            return users
        })
}

export const getTrainees = async () => {
    return db.collection('users').where('userType', '==', 'trainee').get().then(
        function (querySnapshot) {
            let users = []
            let promises = []
            const parseDate = (date) => new Date(date?.seconds * 1000 + date?.nanoseconds / 1000000)

            querySnapshot.forEach(function (doc) {
                users.push({
                    'id': doc.id,
                    'firstName': doc.data().firstName,
                    'lastName': doc.data().lastName,
                    'middleName': doc.data().middleName,
                    'email': doc.data().email,
                    'mobileNumber': doc.data().mobileNumber,
                    'countryCode': doc.data().countryCode,
                    'date_registered': parseDate(doc.data().date_registered),
                    'birthday': parseDate(doc.data().birthday),
                })
            })

            users.forEach((user) => {
                promises.push(
                    db
                        .collection('sessions')
                        .orderBy('traineeName')
                        .startAt(user.firstName)
                        .endAt(user.lastName)
                        .get()
                )
            })

            Promise.all(promises).then((docs) => {
                docs.forEach((sessionDoc) => {
                    if (sessionDoc.size === 0) {
                        users.sessions = null
                        return
                    }

                    sessionDoc.forEach((session) => {
                        users.sessions = session.data()
                    })
                })
            })

            return users
        })
}

export const getNotVerifiedUsers = async () => {
    return db
        .collection('users')
        .where('ptInfo.status', '==', 'pending')
        .where('userType', '==', 'pt')
        .get()
        .then(function (querySnapshot) {
            let users = []
            querySnapshot.forEach(function (doc) {
                users.push({
                    id: doc.id,
                    firstName: doc.data().firstName,
                    lastName: doc.data().lastName,
                    middleName: doc.data().middleName,
                    birthday: epochToDate(doc.data().birthday.seconds),
                    status: doc.data().ptInfo.status,
                    awardsAndExperiences: doc.data().ptInfo
                        ? doc.data().ptInfo.awardsAndExperiences
                        : 'none',
                    downloadablesCV: doc.data().ptInfo
                        ? doc.data().ptInfo.cv
                        : 'none',
                    downloadablesCERT: doc.data().ptInfo
                        ? doc.data().ptInfo.certificates
                        : 'none',
                })
            })
            return users
        })
}

const getPaymentSessionPerPSize = async () => {
    let refunds = []
    let refundEmails = []
    let duplicateEmails = []
    let array_elements = []
    // get startDtae and endDate of current week
    let now = new Date()
    let days = now.getDay()
    let first = now.getDate() - days + (days === 0 ? -6 : 0) // working
    let last = first + 6
    let firstday = new Date(now.setDate(first))
    let firstday1 = new Date(firstday.setHours(0, 0, 0)) // working
    let lastday = new Date(now.setDate(last))
    let lastday1 = new Date(lastday.setHours(23, 59, 0)) // working

    // console.log(days)
    // console.log(firstday1)
    // console.log(lastday1)
    let paymentSnapshot = await db
        .collectionGroup('sessions')
        .where('status', 'in', ['done', 'cancelled_trainee', 'expired'])
        // .orderBy("targetStartTs")
        .where('targetStartTs', '>=', firstday1) // working
        .where('targetStartTs', '<', lastday1) // working
        .where('paid', '==', false)
        .get()

    let ctr = 0
    paymentSnapshot.forEach(async function (sesh) {
        let result = refundEmails.includes(sesh.data().ptEmail)
        array_elements.push(sesh.data().ptEmail)
        if (result === false) {
            refundEmails.push(sesh.data().ptEmail)
            refunds.push({
                id: sesh.id,
                subscriptionId: sesh.data().subscriptionId,
                endSesh: epochToDate(sesh.data().actualEndTs),
                fullName: sesh.data().ptEmail,
                trainee: sesh.data().trainneeEmail,
                status: sesh.data().status,
                paidStat: sesh.data().paid,
                paidStatus: sesh.data().paid === true ? 'PAID' : 'PENDING',
                subDate: epochToDate(sesh.data().targetStartTs.seconds),
                rate: sesh.data().rate,
                enablePayBtn: days === 0 ? 'true' : 'false',
            })
            ctr = ctr + 1
        } else {
            duplicateEmails.push(sesh.data().ptEmail)
        }
    })
    array_elements.sort()
    var current = null
    var emailSize = []
    var cnt = 0
    for (var i = 0; i < array_elements.length; i++) {
        if (array_elements[i] !== current) {
            if (cnt > 0) {
                emailSize.push({
                    email: current,
                    size: cnt,
                })
            }
            current = array_elements[i]
            cnt = 1
        } else {
            cnt++
        }
    }
    if (cnt > 0) {
        emailSize.push({
            email: current,
            size: cnt,
        })
    }
    let data = {
        groupedList: refunds,
        emailSize: emailSize,
    }
    // console.log(data)
    return data
}

// getVerifiedTrainers v1 Payments Page //
export const getVerifiedTrainers = async () => {
    let sessionL = await getPaymentSessionPerPSize()
    let sessions = []
    sessionL.groupedList.forEach(async function (sesh) {
        sessionL.emailSize.forEach(function (emailS) {
            if (emailS.email === sesh.fullName) {
                let totalIncome = sesh.rate * emailS.size
                let twentyPercent = totalIncome * 0.2
                let weeklyIncome = totalIncome - twentyPercent
                sessions.push({
                    id: sesh.id,
                    subscriptionId: sesh.subscriptionId,
                    fullName: sesh.fullName,
                    trainee: sesh.trainee,
                    status: sesh.status,
                    paidStat: sesh.paidStat,
                    paidStatus: sesh.paidStatus,
                    rate: sesh.rate,
                    weeklyIncome: weeklyIncome,
                    twentyPercent: twentyPercent.toFixed(2),
                    netIncome: totalIncome.toFixed(2),
                    numberOfSessions: emailS.size,
                    enablePayBtn: sesh.enablePayBtn,
                })
            }
        })
    })
    // console.log(sessions)
    return sessions
}

const getRefundSessionPerTraineeIdSize = async () => {
    let refunds = []
    let refundEmails = []
    let duplicateEmails = []
    let array_elements = []
    // get startDtae and endDate of current week
    let now = new Date()
    let days = now.getDay()
    let first = now.getDate() - days + (days === 0 ? -6 : 0) // working
    let last = first + 6
    let firstday = new Date(now.setDate(first))
    let firstday1 = new Date(firstday.setHours(0, 0, 0)) // working
    let lastday = new Date(now.setDate(last))
    let lastday1 = new Date(lastday.setHours(23, 59, 0)) // working
    let refundSnapshot = await db
        .collectionGroup('sessions')
        .where('status', 'in', ['cancelled_pt', 'cancelled', 'refund'])
        // .orderBy('targetStartTs')
        .where('targetStartTs', '>=', firstday1) // working
        .where('targetStartTs', '<', lastday1) // working
        .where('paid', '==', false)
        .get()
    let ctr = 0
    refundSnapshot.forEach(async function (sesh) {
        let result = refundEmails.includes(sesh.data().trainneeEmail)
        array_elements.push(sesh.data().trainneeEmail)
        if (!result) {
            refundEmails.push(sesh.data().trainneeEmail)
            refunds.push({
                id: sesh.id,
                subscriptionId: sesh.data().subscriptionId,
                endSesh: epochToDate(sesh.data().actualEndTs),
                fullName: sesh.data().ptEmail,
                trainee: sesh.data().trainneeEmail,
                status: sesh.data().status,
                paidStat: sesh.data().paid,
                paidStatus: sesh.data().paid ? 'PAID' : 'PENDING',
                rate: sesh.data().rate,
            })
            ctr = ctr + 1
        } else {
            duplicateEmails.push(sesh.data().trainneeEmail)
        }
    })
    array_elements.sort()
    var current = null
    var emailSize = []
    var cnt = 0
    for (var i = 0; i < array_elements.length; i++) {
        if (array_elements[i] !== current) {
            if (cnt > 0) {
                emailSize.push({
                    email: current,
                    size: cnt,
                })
            }
            current = array_elements[i]
            cnt = 1
        } else {
            cnt++
        }
    }
    if (cnt > 0) {
        emailSize.push({
            email: current,
            size: cnt,
        })
    }
    let data = {
        groupedList: refunds,
        emailSize: emailSize,
    }
    return data
}

// getVerifiedTrainers v1 Refunds Page //
export const getVerifiedTrainers1 = async () => {
    let sessionL = await getRefundSessionPerTraineeIdSize()
    let sessions = []
    sessionL.groupedList.forEach(async function (sesh) {
        sessionL.emailSize.forEach(function (emailS) {
            if (emailS.email === sesh.trainee) {
                sessions.push({
                    id: sesh.id,
                    subscriptionId: sesh.subscriptionId,
                    fullName: sesh.fullName,
                    trainee: sesh.trainee,
                    status: sesh.status,
                    paidStat: sesh.paidStat,
                    paidStatus: sesh.paidStatus,
                    rate: sesh.rate,
                    totalRefund: sesh.rate * emailS.size,
                    numberOfSessions: emailS.size,
                })
            }
        })
    })
    return sessions
}

export const getSubscription = async (user) => {
    let sessions = []

    return db
        .collectionGroup('sessions')
        .where('status', '==', 'done')
        .orderBy('subscriptionId')
        .get()
        .then(function (querySnapshot) {
            if (querySnapshot.size !== 0) {
                let totalIncome = user.data().rate * querySnapshot.size
                let twentyPercent = totalIncome * 0.2
                let weeklyIncome = totalIncome - twentyPercent
                sessions.push({
                    fullName: user.data().ptEmail,
                    trainee: user.data().trainneeEmail,
                    subDate: epochToDate(user.data().actualEndTs.seconds),
                    endDate: epochToDate(user.data().actualStartTs.seconds),
                    rate: user.data().rate,
                    email: user.data().ptEmail,
                    totalIncome: totalIncome,
                    twentyPercent: twentyPercent.toFixed(2),
                    weeklyIncome: weeklyIncome,
                })
            }
        })
}

const handleEmailNotification = async (userId, code) => {
    let token = await localStorage.getItem('token')

    if (code === undefined) {
        code = 'EMAIL_VERIFIED_BY_ADMIN'
    }

    let email = await axios.post(
        API_URL + '/email/send',
        {
            id: userId,
            code: code,
        },
        {
            headers: {
                Accept: 'application/x-www-form-urlencoded',
                'X-HouseFit-Authorization': token,
            },
        }
    )

    email.then((response) => {
        // console.log('This is an email response', response);
    })
}

export const verifyUsers = async (ids) => {
    let batch = db.batch()

    ids.forEach((id) => {
        // console.log('Starting update of unverified users');
        let userRef = db.collection('users').doc(id)
        batch.update(userRef, 'ptInfo.status', 'approved')
    })

    return batch
        .commit()
        .then((response) => {
            ids.forEach((id) => {
                // console.log(id);
                handleEmailNotification(id, 'EMAIL_VERIFIED_BY_ADMIN')
            })

            return response
        })
        .catch((err) => {
            // console.log('error',err);
        })
}

export const approveGyms = async (ids) => {
    let batch = db.batch()

    ids.forEach((id) => {
        // console.log('Starting update of unverified users');
        let userRef = db.collection('users').doc(id)
        batch.update(userRef, 'ptInfo.status', 'approved')
    })

    return batch
        .commit()
        .then((response) => {
            ids.forEach((id) => {
                handleEmailNotification(id, 'EMAIL_VERIFIED_BY_ADMIN_AS_GYM')
            })

            return response
        })
        .catch((err) => {})
}

export const handleSignUp = (email, password) => {
    if (email === null || password === null) {
        return
    }

    return auth
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const handleLogin = (email, password) => {
    return auth
        .signInWithEmailAndPassword(email, password)
        .then(async (userCredential) => {
            const user = userCredential.user
            const uid = user.uid

            const userDoc = await db.collection('users').doc(uid).get()

            if (userDoc.exists) {
                const userData = userDoc.data()
                const userTypeField = userData.userType

                if (userTypeField === 'admin') {
                    return userCredential
                }
            } else {
                return { message: 'You are not allowed to perform this action', code: 'invalid user' }
            }
        })
        .catch((err) => {
            return err
        })
}

export const handleSignout = () => {
    auth.signOut()
        .then((res) => {
            localStorage.removeItem('token')
        })
        .catch((err) => {
            localStorage.removeItem('token')
        })
}

export const getUserToken = async () => {
    return auth.currentUser.getIdToken(/* forceRefresh */ true)
}

export const pendingApprovalGyms = async () => {
    var list = await db
        .collection('users')
        .where('ptInfo.status', '==', 'pending')
        .where('userType', '==', 'gym')
        .get()

    return list.docs.map((doc) => {
        const data = doc.data() ?? {}
        return {
            id: doc.id,
            name: data.lastName,
            status: data.ptInfo?.status,
            awardsAndExperiences: data.ptInfo?.awardsAndExperiences ?? [],
            certificates: data.ptInfo?.certificates ?? [],
            email: data.email,
        }
    })
}
