import React, {useContext, useState} from 'react'
import {db} from '../../services/Firebase';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button'
import MUItheme from '../../themes/styled-components/MuiTheme'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import styled from "styled-components";
import DatePicker from 'react-date-picker';
import TextField from '@material-ui/core/TextField'

import {ModalContext} from '../../contexts/ModalContext1';

import {PAYPAL_PAYOUT_API, auth, uuidv4} from '../../services/Paypal';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0, 3),
  },
  modalHeading: {
    padding: '0.5rem',
    fontWeight: 'bold'
  },
  modalBody: {
    padding: '1rem'
  },
  modalButtons: {
    alignContent: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '100%'
  }
}));

const Ul = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-bottom: 4rem;

    li {
      display: inline-block;
    }

    .float-right {
      float: right;
    }

    .float-left {
      float: left;
    }
`;

const RefundNotificationModal = () => {
    const classes = useStyles();

    const {
      isOpen,
      setIsOpen,
      modalData,
    } = useContext(ModalContext);

    const [error, setError] = useState("");
    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);

    const handleClose = () => {
      setIsOpen(false); // this is for the modal
      setIsSuccess(false);
      setOpen(false);// this is for the alert
      setRefId();
    };

    const getPayPalAuth = async () => {
      return await auth;
    }

    const [refId, setRefId] = useState("");
    const [value, onChange] = useState(new Date());



    const handlePayment = (e) => {
      e.preventDefault();


      let session = db.collection('subscriptions/'+ modalData.subscriptionId + '/sessions').doc(modalData.sessionId);
      session.update({
        'paid':true,
        'paymentRefId': refId,
        'paymentTimestamp': value
      });

      if (session.error) {
        setError(session.error);
        setIsOpen(false);
      } else {
        setIsSuccess(true);
        setRefId();
        // setIsOpen(false);
      }

    }
  
    return (
      <div theme = {MUItheme}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isOpen}>
            <div className={classes.paper}>
              <Snackbar open={isSuccess} autoHideDuration={3000}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="success">
                  Update Success! Kindly close the modal and go to PAYMENTS page and then click REFUNDS to see updated list.
                </Alert>
              </Snackbar>

              {/* <Snackbar open={open} autoHideDuration={3000}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                  <Alert severity="warning">
                  {error}
                  </Alert>
              </Snackbar> */}
              <Ul>
                <li className = "float-left">
                  <Typography variant="body1" className={classes.modalHeading}>
                    REFUND TRANSACTION BREAKDOWN
                  </Typography> 
                </li>
                <li className = "float-right"> 
                  <Typography variant="body1" className={classes.modalHeading}>
                    <ClearIcon onClick={handleClose}/>
                  </Typography> 
                </li>
              </Ul>
              <hr></hr>
              <div className={classes.modalBody}>
                {/* <p>You are about to pay {modalData.nanme} of an amount: {modalData.currency} {modalData.amount}</p> */}
                <form>
                  <div className="text">
                    <p> Trainee Email: {modalData.traineeEmail} </p>
                    {/* <p> Breakdown: </p> */}
                    <p> Total Refund: {modalData.totalRefund} </p>
                    
                    <div className="form-inputs">
                        <TextField
                          className="refId"
                          onChange={(e) => setRefId(e.target.value)}
                          value={refId}
                          placeholder="Reference ID"
                          type="text"
                          style = {{
                            paddingBottom : '10px',
                          }}
                        />

                        <label> Payment Date: </label>
                        <DatePicker
                          className="datePicker"
                          onChange={onChange}
                          value={value}
                          style = {{
                            marginBottom : '10px',
                          }}
                        />
                    </div>
                  </div>
                  {/* <input
                    value={tweetImage}
                    onChange={(e) => setTweetImage(e.target.value)}
                    className="text"
                    placeholder=""
                    type="text"
                  /> */}

                  <ButtonGroup className={classes.modalButtons}>
                    <Button 
                        onClick={handlePayment}
                        size = "small"
                        style = {{
                            marginRight: 12,
                            background: 'blue',
                            borderRadius: 5,
                            width: '67px'
                        }}
                        variant = "contained" 
                        color = "secondary"
                        type = "submit"> 
                        OK 
                      </Button>
                    <Button 
                        onClick={handleClose}
                        size = "small"
                        style = {{
                            marginRight: 12,
                            background: 'black',
                            borderRadius: 5,
                            width: '67px'
                        }}
                        variant = "contained" 
                        color = "secondary"
                        type = "button"> 
                        CLOSE 
                      </Button>
                    </ButtonGroup>
                </form>


                
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }

export default RefundNotificationModal;