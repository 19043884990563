import React, {useContext} from 'react'
import 'fontsource-roboto';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {ServicesContext} from '../../contexts/ServicesContextProvider';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const SessionsTableComponent = () => {
  const {sessions} = useContext(ServicesContext);
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>FULLNAME</StyledTableCell>
                        <StyledTableCell>PT NAME</StyledTableCell>
                        <StyledTableCell>INCLUSIVE DATES</StyledTableCell>
                        <StyledTableCell>STATUS</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessions.map((row) => (
                        <StyledTableRow key={row.id}>
                        <StyledTableCell>
                            {row.traineeName}
                        </StyledTableCell>
                        <StyledTableCell>{row.ptName}</StyledTableCell>
                        <StyledTableCell>{row.sessionStart+"-"+row.sessionEnd}</StyledTableCell>
                        <StyledTableCell>{row.status}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default SessionsTableComponent;
