// Sidebar component
import Typography from "@material-ui/core/Typography";
import "fontsource-roboto";
import React from "react";
import styled from "styled-components";
import { GymContextProvider } from "../../contexts/GymContextProvider";
import ConfirmationModalGym from "../modal/ConfirmationModalGym";
import ForVerificationTableGymComponent from "../table/ForVerificationTableGym";

const Div = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
`;

const ForVerificationGymContent = () => {
    return (
        <GymContextProvider>
            <Div>
                <Typography variant="h6" component="div">
                    GYM - FOR VERIFICATION
                </Typography>
                <ConfirmationModalGym />
                <ForVerificationTableGymComponent />
            </Div>
        </GymContextProvider>
    );
};

export default ForVerificationGymContent;
