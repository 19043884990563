import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../contexts/AuthContextProvider";
import logo from "../images/logo.png";
import { handleLogin } from "../services/UsersService";
import media from "../themes/media-query/Device";
import "../themes/styles/App.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginForm = styled.div`
    background-color: white;
    width: 100%;

    img {
        width: 100%;
    }

    .userEmail,
    .userPassword {
        margin-bottom: 1rem;
        width: 80%;
    }

    .loginLabel {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .loginButton {
        width: 80%;
        margin-bottom: 3rem;
    }

    @media ${media.desktop} {
        width: 30%;

        .userEmail,
        .userPassword {
            width: 70%;
        }

        .loginButton {
            width: 70%;
        }
    }

    a {
        text-decoration: none !important;
    }
`;

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { setCurrentUser } = useContext(AuthContext);
    const [error, setError] = useState("");
    const [open, setOpen] = React.useState(false);

    const history = useHistory();

    const routeChange = (route) => {
        history.push(route);
    };

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    };

    const handleUserLogin = async (event) => {
        event.preventDefault();

        handleLogin(email, password).then((response) => {
            if (response.code !== undefined) {
                setError(response.message);
                setOpen(true);

                setTimeout(() => {
                    setOpen(false);
                }, 5000);

                routeChange("/");

                return;
            }

            (async () => {
                const token = await response.user.getIdToken();
                console.log(token);

                //clear token first.
                localStorage.removeItem("token");

                localStorage.setItem("token", token);

                setCurrentUser(response);

                routeChange("/dashboard");
            })();
        });
    };

    return (
        <div className="App">
            <header className="App-header">
                <LoginForm onSubmit={handleUserLogin}>
                    <img src={logo} alt="login-logo"></img>
                    <Typography
                        variant="h5"
                        color="primary"
                        className="loginLabel"
                    >
                        {" "}
                        LOGIN{" "}
                    </Typography>

                    <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert severity="warning">{error}</Alert>
                    </Snackbar>

                    <TextField
                        variant="outlined"
                        color="secondary"
                        type="email"
                        label="email"
                        className="userEmail"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <TextField
                        variant="outlined"
                        color="secondary"
                        type="password"
                        label="password"
                        className="userPassword"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="loginButton"
                        disabled={!validateForm}
                        onClick={handleUserLogin}
                    >
                        LOGIN
                    </Button>
                </LoginForm>
            </header>
        </div>
    );
};

export default LoginPage;
