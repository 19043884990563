
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import { Link } from "react-router-dom"
import styled from "styled-components"
import { handleSignout } from '../../services/UsersService'
import color from '../../themes/font-colors/TextColor'

const Accordion = withStyles({
    root: {
      background: 'transparent',
      color: 'white',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      }
    }
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
        background: 'transparent',
        padding: '0',
        margin: '0',
        minHeight: 12,
        fontSize: 15,
        '&$expanded': {
          minHeight: 12,
        },
      },
      content: {
        margin: '0'
      }
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
        background: 'transparent',
        height: '100vh'
    }
  }))(MuiAccordionDetails);

const Logo = styled.div`
    margin-left: 5rem;
    text-align: center;
    justify: center;
`;

const Ul = styled.ul`
    list-style-type: none;
    padding: 0;
    font-size: 1rem;
    height: 100%;

    li {
        text-align: left;
        color: ${color.default.white};
        padding: 0.8rem;
    }

    li:hover {
        background-color: ${color.hover.primaryHover}
    }

    .dropdown {
        padding-left: 3rem !important;
    }

    .dropdown:hover {
        background-color: ${color.hover.primaryHover}
    }

    a {
      text-decoration: none !important;
    }
`;

function MobileNavbar() {
    return (
        <AppBar color="primary" style = {{ background: '#131A2A'}}>
            <Toolbar>
                <IconButton style = {{ color: 'white'}}>
                  <Accordion>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <MenuIcon>
                        </MenuIcon>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Ul>
                            <Link to = "/dashboard"> <li> Personal Trainers </li> </Link>
                            <Link to = "/for-verification" ><li> For Verification (PT) </li></Link>
                            <Link to = "/for-verification-gym" ><li> For Verification (Gym) </li></Link>
                            <Link to = "/trainees"><li> Trainees </li></Link>
                            <Link to = "/classes"><li> Classes </li></Link>
                            <Link to = "/subscriptions"><li> Subscriptions </li></Link>
                            {/* <Link to = "/sessions"><li> Sessions </li></Link> */}
                            <Link to = "/payments"><li> Payments  </li></Link>
                            <Link to = "/refunds"><li> Refunds  </li></Link>
                            <Link to = "/incident-reports"><li> Incident Reports</li></Link>
                            <Link to = "/email"><li>Email</li></Link>
                            <Link to = "/notifications"><li>Notifications</li></Link>
                            <Link to = "/" onClick={() => handleSignout()}><li> Logout</li></Link>
                        </Ul>
                    </AccordionDetails>
                  </Accordion>
                  <Logo>
                  </Logo>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
  }

  export default MobileNavbar;