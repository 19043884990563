import React, {useContext, useState, useEffect} from 'react'
import 'fontsource-roboto';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {UsersContext} from '../../contexts/UsersContextProvider';
import { Countries } from "../../utils/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    color: 'white',
    "&:hover": {
      color: 'white',
    },
    '&$active': {
      color: 'white',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
    opacity: 1,
  },
}))(TableSortLabel);

const TraineesTableComponent = () => {
  const {trainees} = useContext(UsersContext);
  const [filters, setFilters] = useState({
    filterName: '',
    filterCountry: '',
    filterStartRegisteredDate: '',
    filterEndRegisteredDate: '',
    filterStartBirthday: '',
    filterEndBirthday: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredTraineeList, setFilteredTraineeList] = useState(trainees);
  const [orderBy, setOrderBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && sortDirection === 'asc';
    setOrderBy(property);
    setSortDirection(isAscending ? 'desc' : 'asc');
  };

  const onFilterByName = (arrayToFilter) => {
    if (filters.filterName) {
      return arrayToFilter.filter((item) => item.firstName.toLowerCase().includes(filters.filterName.toLowerCase()) || item.lastName.toLowerCase().includes(filters.filterName.toLowerCase()) || item.email.toLowerCase().includes(filters.filterName.toLowerCase()));
    } else {
      return arrayToFilter
    }
  }

  const onFilterByCountry = (arrayToFilter) => {
    if (filters.filterCountry) {
      return arrayToFilter.filter((item) => item.countryCode.toLowerCase().includes(filters.filterCountry.toLowerCase()));
    } else {
      return arrayToFilter
    }
  }

  const onFilterByDate = (arrayToFilter) => {
    if (filters.filterStartRegisteredDate && filters.filterEndRegisteredDate) {
      return arrayToFilter.filter((item) => {
        return dayjs(item.date_registered).isSameOrAfter(filters.filterStartRegisteredDate, 'day') && dayjs(item.date_registered).isSameOrBefore(filters.filterEndRegisteredDate, 'day');
      });
    } else {
      return arrayToFilter
    }
  }

  const onFilterByBirthdate = (arrayToFilter) => {
    if (filters.filterStartBirthday && filters.filterEndBirthday) {
      return arrayToFilter.filter((item) => {
        return dayjs(item.birthday).isSameOrAfter(filters.filterStartBirthday, 'day') && dayjs(item.birthday).isSameOrBefore(filters.filterEndBirthday, 'day');
      });
    } else {
      return arrayToFilter
    }
  }

  useEffect(() => {
    let result = trainees;
    result = onFilterByName(result);
    result = onFilterByCountry(result);
    result = onFilterByDate(result);
    result = onFilterByBirthdate(result);
    result = sortByDate(result);
    setFilteredTraineeList(result);
    setPage(0)
  }, [filters]);

  const sortByDate = (arrayToSort) => {
    return arrayToSort.sort((a, b) => {
      const dateA = dayjs(a[orderBy]);
      const dateB = dayjs(b[orderBy]);

      if (!dateA.isValid()) return 1; // invalid dates come last
      if (!dateB.isValid()) return -1; // invalid dates come last
      if (dateA.isSame(dateB)) return 0; // dates are equal
      return sortDirection === 'desc'
        ? dateA.isBefore(dateB)
          ? -1
          : 1
        : dateA.isBefore(dateB)
        ? 1
        : -1;
    });
  }

  useEffect(() => {
    const sortedRows = sortByDate(filteredTraineeList)
    setFilteredTraineeList(sortedRows);
  }, [orderBy, sortDirection])

  const handleSearchFilter = (event, key) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: event }));
  }

  const removeFilters = () => {
    setFilters({
      filterName: '',
      filterCountry: '',
      filterStartRegisteredDate: '',
      filterEndRegisteredDate: '',
      filterStartBirthday: '',
      filterEndBirthday: '',
    });
    const filterForm = document.getElementById("filter-form");
    filterForm.reset();
  }

  useEffect(() => {
    setFilteredTraineeList(trainees);
  }, [trainees]);

    return (
        <>
        <form style = {{ paddingBottom : '10px' }} id="filter-form">
          <div>
            <label> Search by Lastname/Firstname/Email: </label>
            <input
              icon='search'
              placeholder='Keyword'
              onChange={(event) => handleSearchFilter(event.target.value, "filterName")}
            />
          </div>
          <div style = {{ marginTop: '10px' }}>
            <label style = {{ whiteSpace : 'nowrap', marginRight: '10px' }}> Filter By Country: </label>
            <select
              defaultValue=""
              onChange={(event) => handleSearchFilter(event.target.value, "filterCountry")}
            >
              {Countries && Countries.map((country) =>
                  <option key={country.code} value={country.code}>{country.name}</option>
              )}
            </select>
          </div>
          <div style = {{ marginTop: '10px' }}>
            <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> Filter Birthday: </label>
            <div style = {{ marginTop: '10px' }}>
              <DatePicker selected={filters.filterStartBirthday} onChange={(date) => handleSearchFilter(date, "filterStartBirthday")} />
              &nbsp; to &nbsp;
              <DatePicker selected={filters.filterEndBirthday} onChange={(date) => handleSearchFilter(date, "filterEndBirthday")} />
            </div>
          </div>
          <div style = {{ marginTop: '10px' }}>
            <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> Filter Date Registered: </label>
            <div style = {{ marginTop: '10px' }}>
              <DatePicker selected={filters.filterStartRegisteredDate} onChange={(date) => handleSearchFilter(date, "filterStartRegisteredDate")} />
              &nbsp; to &nbsp;
            <DatePicker selected={filters.filterEndRegisteredDate} onChange={(date) => handleSearchFilter(date, "filterEndRegisteredDate")} />
            </div>
          </div>
        </form>
        <form style = {{ paddingBottom : '10px', display: 'flex' }}>
            <Button variant="contained" style={{ background: 'black', color: 'white' }} onClick={() => removeFilters()}>Clear Filters</Button>
        </form>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={filteredTraineeList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>FULLNAME</StyledTableCell>
                        <StyledTableCell>EMAIL</StyledTableCell>
                        <StyledTableCell>MOBILE NUMBER</StyledTableCell>
                        <StyledTableCell>COUNTRY CODE</StyledTableCell>
                        <StyledTableCell>BIRTHDAY</StyledTableCell>
                        <StyledTableCell>
                          <StyledTableSortLabel
                              active={orderBy === 'date_registered'}
                              direction={sortDirection}
                              onClick={() => handleSort('date_registered')}
                          >
                              DATE REGISTERED
                          </StyledTableSortLabel>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredTraineeList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>
                              {row.firstName+" "+row.middleName+" "+row.lastName}
                          </StyledTableCell>
                          <StyledTableCell>{row.email}</StyledTableCell>
                          <StyledTableCell>{row.mobileNumber}</StyledTableCell>
                          <StyledTableCell>{row.countryCode}</StyledTableCell>
                          <StyledTableCell>{dayjs(row.birthday).format("MMMM D, YYYY")}</StyledTableCell>
                          <StyledTableCell>{dayjs(row.date_registered).isValid() ? dayjs(row.date_registered).format("dddd, MMMM D YYYY h:mm A") : ''}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={filteredTraineeList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </>
    );
}

export default TraineesTableComponent;
