import React, {createContext, useState, useEffect} from "react";
import {getSubscriptions, getSessions, getReports} from '../services/Service';

export const ServicesContext = createContext({});

const ServicesContextProvider = props => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        getSubscriptions().then(response => {
            setSubscriptions(response);
        });

        getSessions().then(response => {
            setSessions(response);
        });

        getReports().then(response =>{
            setReports(response);
        });
        
    }, []);

    return (
        <ServicesContext.Provider value={{
            subscriptions, 
            getSubscriptions, 
            setSubscriptions, 
            sessions, 
            getSessions, 
            setSessions, 
            reports, 
            getReports, 
            setReports,
            }}>
          {props.children}
        </ServicesContext.Provider>
      );
};

export default ServicesContextProvider;