import {storage} from './Firebase';
import { saveAs } from 'file-saver';

export const downloadCertificate = async (userId, fileName) => {
    let pathReference = storage.ref().child(`users/${userId}/certificates/${fileName}`);

    return  await pathReference.getDownloadURL();
}

export const downloadCV = async (userId, fileName) => {
  let pathReference = storage.ref().child(`users/${userId}/cv/${fileName}`);

  return await pathReference.getDownloadURL();
}

export const _handleDownloadCV = async (userId, filename) => {

  if (userId === 'undefined' || filename === 'none') {
    return;
  }

  let downloadLink = await downloadCV(userId, filename);

  saveAs(downloadLink, filename);
}

export const _handleDownloadCert = async (userId, filename) => {

  if (userId === 'undefined' || filename === 'none') {
    return;
  }

  let downloadLink = await downloadCertificate(userId, filename);

  saveAs(downloadLink, filename);
}