import React from 'react'
import 'fontsource-roboto';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography'
import PaymentsTableComponent from '../table/PaymentsTable'
import PaymentNotificationModal from '../../components/modal/PaymentNotificationModal'
import ModalContextProvider from '../../contexts/ModalContext'

const Div = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
`;

const payments = () => {
    return (
        <Div>
            <Typography variant="h6" component="div">
                PAYMENTS
            </Typography>
            <ModalContextProvider>
                <PaymentsTableComponent />
                <PaymentNotificationModal />
            </ModalContextProvider>
        </Div>    
    );
}

export default payments;