import axios from 'axios';

// Sandbox Creds
const PAYPAL_CLIENT = 'AR0ySznVVbfhm8D_xdkWHvjyP6W112o8c4CadxhI_bdApj1L6UrbHc2JdZYxksInNFR7TnVGJrisG39y';
const PAYPAL_SECRET = 'ENAbFa_vDGh9ieccEBHlV-KAAkpOmdIi-Ms71B2N_Dqzglqho9oRoVdSZv6foUtAIoqshYAu4UGllF5O';

// Prod Creds
// const PAYPAL_CLIENT = 'AcEfzYklRgOToagnOaDBcAMkQ4ysmpRqTQeFaH_JRSndOHzMsjH5qFfQU2n4mSUxWNDx6vbRiLi3edjG';
// const PAYPAL_SECRET = 'EIKeUlRYXjAD937uGdTQhov11pTCRPEttPVrxuDMlYJ9aSdDmMvDyiMZ4GrwHoEXv2Gv1wptNBSZFEMZ';

const PAYPAL_OAUTH_API = 'https://api.sandbox.paypal.com/v1/oauth2/token/';
// export const PAYPAL_PAYOUT_API = 'https://api.sandbox.paypal.com/v1/payments/payouts';
export const PAYPAL_PAYOUT_API = 'https://api-m.sandbox.paypal.com/v1/payments/payouts';

const basicAuth = btoa(`${ PAYPAL_CLIENT }:${ PAYPAL_SECRET }`);
// console.log('basicAuth ' + basicAuth)
export const auth = axios.post(
    PAYPAL_OAUTH_API,
    'grant_type=client_credentials',
    {
      'headers': {
        'Content-Type':'application/x-www-form-urlencoded',
        'Authorization': `Basic ${ basicAuth }`,
      }
    }
).then(response => {
    return response;
}).catch(errr => {
  console.log('paypal error', errr);
});

export const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}