import React from 'react'
import 'fontsource-roboto';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography'
import SessionsTableComponent from '../table/SessionsTable'

const Div = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
`;

const SessionsContentComponent = () => {
    return (
        <Div>
            <Typography variant="h6" component="div">
                SESSIONS
            </Typography>
            <SessionsTableComponent />
        </Div>    
    );
}

export default SessionsContentComponent;