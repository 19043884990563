// Sidebar component
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../images/logo.png'
import { handleSignout } from '../../services/UsersService'
import color from '../../themes/font-colors/TextColor'
import media from '../../themes/media-query/Device'
import theme from '../../themes/styled-components/ComponentTheme'

const Div = styled.div`
    height: 1080px;
    // height: 100%;
    width: 100%;
    background: ${theme.sidebar.background};
    text-align: center;
    display: none;

    @media ${media.desktop} {
        display: block;
    }
`;

const Ul = styled.ul`
    list-style-type: none;
    padding-left: 0;

    li {
        text-align: left;
        color: ${color.default.white};
        padding: 0.8rem;
    }

    li:hover {
        background-color: ${color.hover.primaryHover}
    }

    .dropdown {
        padding: 0.8rem !important;
    }

    .dropdown:hover {
        background-color: ${color.hover.primaryHover}
    }

    .dropdown.active {
        background-color: ${color.hover.primaryHover}
    }

    a {
      text-decoration: none !important;
    }
`;

export default function Sidebar() {
    return (
        <Div>
            <img src = {logo} alt = "logo" width = "100%" />

            <Ul>
                <Link to = "/dashboard"> <li> Personal Trainers </li> </Link>
                <Link to = "/for-verification" ><li> For Verification (PT) </li></Link>
                <Link to = "/for-verification-gym" ><li> For Verification (Gym) </li></Link>
                <Link to = "/trainees"><li> Trainees </li></Link>
                <Link to = "/classes"><li> Classes </li></Link>
                <Link to = "/subscriptions"><li> Subscriptions </li></Link>
                {/* <Link to = "/sessions"><li> Sessions </li></Link> */}
                <Link to = "/payments"><li> Payments  </li></Link>
                <Link to = "/refunds"><li> Refunds  </li></Link>
                <Link to = "/incident-reports"><li> Incident Reports</li></Link>
                <Link to = "/email"><li>Email</li></Link>
                <Link to = "/notifications"><li>Notifications</li></Link>
                <Link to = "/" onClick={() => handleSignout()}><li> Logout</li></Link>
            </Ul>
        </Div>
    );
  }
