import React, { createContext, useEffect, useState } from 'react'
import {
    getAllUsers,
    getClasses,
    getNotVerifiedUsers,
    getTrainees,
    getVerifiedTrainers, getVerifiedTrainers1,
    verifyUsers
} from '../services/UsersService'
import { epochToDate } from '../services/Helper'

export const UsersContext = createContext({})

const UsersContextProvider = props => {
    const [users, setUsers] = useState([])
    const [classList, setClassList] = useState([])
    const [notVerifiedUsers, setNotVerifiedUsers] = useState([])
    const [toBeVerified, setToBeVerified] = useState([])
    const [trainees, setTrainees] = useState([])
    const [verifiedTrainers, setVerifiedTrainers] = useState([])
    const [verifiedTrainers1, setVerifiedTrainers1] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getClasses().then(response => {
            setClassList(response)
        })
        setLoading(true);
        getAllUsers().then(response => {
            setAllUsers(response)

            const pts = response.filter(user => user.userType === 'pt').map(user => {
                return {
                    ...user,
                    birthday: user.birthday ? epochToDate(user.birthday.seconds) : '',
                    gender: user.gender ? user.gender : 'none',
                    status: user.ptInfo.reported === false ? user.ptInfo.status : 'reported',
                    awardsAndExperiences: user.ptInfo ? user.ptInfo.awardsAndExperiences : 'none',
                    downloadablesCV: user.ptInfo ? user.ptInfo.cv : 'none',
                    downloadablesCERT: user.ptInfo ? user.ptInfo.certificates : 'none',
                }
            })
            setUsers(pts);
            setNotVerifiedUsers(pts.filter(user => user?.ptInfo?.status === 'pending'));

            const parseDate = (date) => new Date(date?.seconds * 1000 + date?.nanoseconds / 1000000)
            const trainees = response
                .filter(user => user.userType === 'trainee')
                .map(user => {
                    return {
                        ...user,
                        'date_registered': parseDate(user.date_registered),
                        'birthday': parseDate(user.birthday)
                    }
                })
            setTrainees(trainees);
            setLoading(false);
        })

        getVerifiedTrainers().then(response => {
          setVerifiedTrainers(response);
        });

        getVerifiedTrainers1().then(response => {
          setVerifiedTrainers1(response);
        });

    }, [])

    return (
        <UsersContext.Provider value={{
            classList,
            users,
            notVerifiedUsers,
            toBeVerified,
            setToBeVerified,
            verifyUsers,
            getNotVerifiedUsers,
            setNotVerifiedUsers,
            trainees,
            getTrainees,
            setTrainees,
            verifiedTrainers,
            verifiedTrainers1,
            allUsers,
            loading
        }}>
            {props.children}
        </UsersContext.Provider>
    )
}

export default UsersContextProvider