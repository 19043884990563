import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

// Dont delete this.
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// }
// firebase.initializeApp(firebaseConfig);

// DEV
// firebase.initializeApp({
//   apiKey: "AIzaSyBGkj2tOnpOkxbFGj8hDuAXnQvod5Y1pNk",
//   authDomain: "housefit-dev.firebaseapp.com",
//   projectId: "housefit-dev",
//   storageBucket: "housefit-dev.appspot.com",
//   messagingSenderId: "294181981671",
//   appId: "1:294181981671:web:d43a567143cfc22002c142",
//   measurementId: "G-HKLL4V8E89"
// });

firebase.initializeApp({
  apiKey: "AIzaSyAL2EvMbDAtzm5zKIDUapzsg6CVlS-hkQY",
  authDomain: "zippy-cab-300202.firebaseapp.com",
  databaseURL: "https://zippy-cab-300202-default-rtdb.firebaseio.com",
  projectId: "zippy-cab-300202",
  storageBucket: "zippy-cab-300202.appspot.com",
  messagingSenderId: "689809995662",
  appId: "1:689809995662:web:decbfeee2739068e29d6df"
});

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

export default firebase;
