import React from 'react'
import 'fontsource-roboto';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography'
import ClassesTableComponent from '../table/ClassesTable'

const Div = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
`;

const classes = () => {
    return (
        <Div>
            <Typography variant="h6" component="div">
                Classes
            </Typography>
            <ClassesTableComponent />
        </Div>
    );
}

export default classes;