// Sidebar component
import React, { useContext, useState } from 'react'
import 'fontsource-roboto'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import BlockIcon from '@material-ui/icons/Block'
import FeedbackIcon from '@material-ui/icons/Feedback'
import { UsersContext } from '../../contexts/UsersContextProvider'
import { _handleDownloadCert, _handleDownloadCV } from '../../services/DownloadService'
import Button from '@material-ui/core/Button'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const handleStatusIcon = (status) => {
    let icon
    switch (status) {
        case 'approved':
            icon = (<CheckCircleIcon style={{ paddingLeft: '1em' }}/>)
            break
        case 'reported':
            icon = (<BlockIcon style={{ paddingLeft: '1em' }}/>)
            break
        case 'pending':
            icon = (<FeedbackIcon style={{ paddingLeft: '1em' }}/>)
            break
        default:
            icon = status
    }

    return icon
}

const TableComponent = () => {
    const { users } = useContext(UsersContext)
    const [searchTerm, setSearchTerm] = useState('')

    return (
        <TableContainer component={Paper}>
            <form style={{
                paddingBottom: '10px',
            }}>
                <label> Search by Lastname or Firstname: </label>
                <input
                    icon="search"
                    placeholder="Keyword"
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}
                />
            </form>

            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>STATUS</StyledTableCell>
                        <StyledTableCell>LASTNAME</StyledTableCell>
                        <StyledTableCell>FIRSTNAME</StyledTableCell>
                        <StyledTableCell>MIDDLENAME</StyledTableCell>
                        <StyledTableCell>EMAIL</StyledTableCell>
                        <StyledTableCell>GENDER</StyledTableCell>
                        <StyledTableCell>AWARD/EXPERIENCES</StyledTableCell>
                        <StyledTableCell>DOWNLOADABLES</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.filter((val) => {
                        if (searchTerm === '') {
                            return true
                        } else if (val.firstName.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        } else if (val.lastName.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                        return false
                    }).map(row => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell> {handleStatusIcon(row.status)} </StyledTableCell>
                            <StyledTableCell>
                                {row.lastName}
                            </StyledTableCell>
                            <StyledTableCell>{row.firstName}</StyledTableCell>
                            <StyledTableCell>{row.middleName}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>{row.gender === 'none' ? '-' : row.gender}</StyledTableCell>
                            <StyledTableCell>{row.awardsAndExperiences}</StyledTableCell>
                            <StyledTableCell>
                                <Button onClick={() => _handleDownloadCV(row.id, row.downloadablesCV)}
                                        rel="noopener noreferrer" target="_blank">
                                    {row.downloadablesCV === 'none' ? '-' : row.downloadablesCV}
                                </Button>
                                <Button onClick={() => _handleDownloadCert(row.id, row.downloadablesCERT)}
                                        rel="noopener noreferrer" target="_blank">
                                    {row.downloadablesCERT === 'none' ? '-' : row.downloadablesCERT}
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
    // }
}

export default TableComponent
