import React, {useContext, useState, useEffect} from 'react'
import 'fontsource-roboto';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DatePicker from "react-datepicker";
import {ServicesContext} from '../../contexts/ServicesContextProvider';
import dayjs from 'dayjs';

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const SubscriptionsTableComponent = () => {
  const {subscriptions} = useContext(ServicesContext);
  const [filteredSubscriptionList, setFilteredSubscriptionList] = useState(subscriptions);
  const [filters, setFilters] = useState({
    filterName: '',
    filterStartDate: '',
    filterEndDate: '',
  });

  useEffect(() => {
    setFilteredSubscriptionList(subscriptions);
  }, [subscriptions]);

  const onFilterByDate = (arrayToFilter) => {
    if (filters.filterStartDate && filters.filterEndDate) {
      return arrayToFilter.filter((item) => {
        return dayjs(item.createdDate).isSameOrAfter(filters.filterStartDate, 'day') && dayjs(item.createdDate).isSameOrBefore(filters.filterEndDate, 'day')
      });
    } else {
      return arrayToFilter
    }
  }

  const onFilterByName = (arrayToFilter) => {
    if (filters.filterName) {
      return arrayToFilter.filter((item) => item.trainerName.toLowerCase().includes(filters.filterName.toLowerCase()) || item.traineeName.toLowerCase().includes(filters.filterName.toLowerCase()));
    } else {
      return arrayToFilter
    }
  }

  const handleSearchFilter = (event, key) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: event }));
  }

  useEffect(() => {
    let result = subscriptions;
    result = onFilterByName(result);
    result = onFilterByDate(result);
    setFilteredSubscriptionList(result);
  }, [filters]);

  const removeFilters = () => {
    setFilters({
      filterName: '',
      filterStartDate: '',
      filterEndDate: '',
    });
    const filterForm = document.getElementById("filter-form");
    filterForm.reset();
  }

  return (
      <TableContainer component={Paper}>
          <form style = {{ paddingBottom : '10px' }}  id="filter-form">
            <div>
              <label> Search by Email: </label>
              <input
                icon='search'
                placeholder='Keyword'
                onChange={(event) => handleSearchFilter(event.target.value, "filterName")}
              />
            </div>
            <div style = {{ marginTop: '10px' }}>
              <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> Filter by Date: </label>
            </div>
            <div style = {{ marginTop: '10px' }}>
              <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> Start Date: </label>
              <DatePicker selected={filters.filterStartDate} onChange={(date) => handleSearchFilter(date, "filterStartDate")} />
            </div>
            <div style = {{ marginTop: '10px' }}>
              <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> End Date: </label>
              <DatePicker selected={filters.filterEndDate} onChange={(date) => handleSearchFilter(date, "filterEndDate")} />
            </div>
          </form>
          <form style = {{ paddingBottom : '10px', display: 'flex' }}>
            <Button variant="contained" style={{ background: 'black', color: 'white' }} onClick={() => removeFilters()}>Clear Filters</Button>
          </form>
          <Table aria-label="customized table">
              <TableHead>
                  <TableRow>
                      <StyledTableCell>TRAINEE</StyledTableCell>
                      <StyledTableCell>PT</StyledTableCell>
                      <StyledTableCell>START DATE</StyledTableCell>
                      {/* <StyledTableCell>RATE</StyledTableCell> */}
                  </TableRow>
              </TableHead>
              <TableBody>
                  {filteredSubscriptionList
                    .map((row) => (
                        <StyledTableRow key={row.id}>
                        <StyledTableCell>
                            {row.traineeName}
                        </StyledTableCell>
                        <StyledTableCell>{row.trainerName}</StyledTableCell>
                        <StyledTableCell>{row.createdDate}</StyledTableCell>
                        {/* <StyledTableCell>{row.rate}</StyledTableCell> */}
                        </StyledTableRow>
                    ))}
              </TableBody>
          </Table>
      </TableContainer>
  );
}

export default SubscriptionsTableComponent;
