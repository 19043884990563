import React, { useContext, useEffect, useState } from 'react'
import 'fontsource-roboto';
import { withStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { UsersContext } from '../../contexts/UsersContextProvider';
import { Countries } from "../../utils/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    verticalAlign: 'top'
  },
  root: {
    whiteSpace: 'nowrap'
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    color: 'white',
    "&:hover": {
      color: 'white',
    },
    '&$active': {
      color: 'white',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
    opacity: 1,
  },
}))(TableSortLabel);

const TraineesTableComponent = () => {
  const { classList } = useContext(UsersContext);
  const [filters, setFilters] = useState({
    filterName: '',
    filterCountry: '',
    filterStartDate: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [filteredClassList, setFilteredCLassList] = useState(classList)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onFilterByCountry = (arrayToFilter) => {
    if (filters.filterCountry) {
      return arrayToFilter.filter((item) => item.ptDetails?.countryCode.toLowerCase().includes(filters.filterCountry.toLowerCase()));
    } else {
      return arrayToFilter
    }
  }

  const onFilterByDate = (arrayToFilter) => {
    if (filters.filterStartDate) {
      return arrayToFilter.filter((item) => {
        const sessionRangeFilter = item.sessions.filter(({ targetStartTs }) => targetStartTs >= filters.filterStartDate);
        return sessionRangeFilter.length
      });
    } else {
      return arrayToFilter
    }
  }

  useEffect(() => {
    let result = classList;
    result = onFilterByCountry(result);
    result = onFilterByDate(result);
    result = sortDates(result);
    setFilteredCLassList(result);
    setPage(0)
  }, [filters]);

  const handleSort = (property) => {
    const isAscending = orderBy === property && sortDirection === 'asc';
    setOrderBy(property);
    setSortDirection(isAscending ? 'desc' : 'asc');
    setPage(0)
  };

  const compareDates = (a, b) => {
    // Convert the strings to Day.js objects
    const dateA = a.sessions.length ? dayjs(a.sessions.filter((x) => x.targetStartTs)[0]?.targetStartTs) : null;
    const dateB = b.sessions.length ? dayjs(b.sessions.filter((x) => x.targetStartTs)[0]?.targetStartTs) : null;

    // If either date is invalid, move it to the end of the sort
    if (!dateA) return -1;
    if (!dateB) return 1;

    if (!dateA.isValid()) {
      return -1;
    } else if (!dateB.isValid()) {
      return 1;
    }

    // Compare the dates
    if (dateA.isBefore(dateB)) {
      return -1;
    } else if (dateA.isAfter(dateB)) {
      return 1;
    } else {
      return 0;
    }
  };

  // Function to sort the array in ascending or descending order
  const sortDates = (arr) => {
    // Make a copy of the array to avoid modifying the original
    const sortedArray = arr.slice();

    // Sort the array using the compareDates function
    sortedArray.sort(compareDates);

    // Reverse the array if descending order is specified
    if (sortDirection === 'desc') {
      sortedArray.reverse();
    }

    // Return the sorted array
    return sortedArray;
  };

  useEffect(() => {
    const sortedRows = sortDates(filteredClassList)
    setFilteredCLassList(sortedRows);
  }, [orderBy, sortDirection]);

  const handleSearchFilter = (event, key) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: event }));
  }

  const removeFilters = () => {
    setFilters({
      filterName: '',
      filterCountry: '',
      filterStartDate: '',
    });
    const filterForm = document.getElementById("filter-form");
    filterForm.reset();
  }

  useEffect(() => {
    setFilteredCLassList(classList);
  }, [classList])

    return (
      <>
      <form style = {{ paddingBottom : '10px' }} id="filter-form">
        <div style = {{ marginTop: '10px' }}>
          <label style = {{ whiteSpace : 'nowrap', marginRight: '10px' }}> Filter By Country: </label>
          <select
            defaultValue=""
            onChange={(event) => handleSearchFilter(event.target.value, "filterCountry")}
          >
            {Countries && Countries.map((country) =>
                <option key={country.code} value={country.code}>{country.name}</option>
            )}
          </select>
        </div>
        <div style = {{ marginTop: '10px' }}>
          <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> Filter by Date: </label>
        </div>
        <div style = {{ marginTop: '10px' }}>
          <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> Start Date: </label>
          <DatePicker selected={filters.filterStartDate} onChange={(date) => handleSearchFilter(date, "filterStartDate")} />
        </div>
      </form>
      <form style = {{ paddingBottom : '10px', display: 'flex' }}>
          <Button variant="contained" style={{ background: 'black', color: 'white' }} onClick={() => removeFilters()}>Clear Filters</Button>
      </form>
      <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={filteredClassList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
              <TableHead>
                  <TableRow>
                      <StyledTableCell>
                        FULLNAME
                      </StyledTableCell>
                      <StyledTableCell>EMAIL</StyledTableCell>
                      <StyledTableCell>
                        <StyledTableSortLabel
                              active={orderBy === 'targetStartTs'}
                              direction={sortDirection}
                              onClick={() => handleSort('targetStartTs')}
                          >
                              SESSIONS
                          </StyledTableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>TRAINEES</StyledTableCell>
                      <StyledTableCell>NUMBER OF ATTENDEES</StyledTableCell>
                      <StyledTableCell>TOTAL PRICE</StyledTableCell>
                      <StyledTableCell>TOTAL INCOME</StyledTableCell>
                      <StyledTableCell>COMPANY PROFIT</StyledTableCell>
                      <StyledTableCell>NATIONALITY</StyledTableCell>
                      <StyledTableCell>STATUS</StyledTableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {filteredClassList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{`${row.ptDetails?.name || ''} ${row.ptDetails?.lastName}`}</StyledTableCell>
                          <StyledTableCell>{row.ptDetails?.email}</StyledTableCell>
                          <StyledTableCell>

                               <Table>
                                <TableBody>
                                {row.sessions.map((sess) => (
                                  <StyledTableRow key={sess.id}>
                                    <StyledTableCell>
                                    <p style = {{ marginTop : '0' }}>
                                        <span>
                                          <b>Target Start: </b>{dayjs(sess.targetStartTs).format("dddd, MMMM D YYYY")}
                                        </span>
                                        <br/>
                                        <span>
                                          <b>Status: </b>{sess.status}
                                        </span>
                                      </p>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  )
                                )}
                                </TableBody>
                              </Table>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.traineeDetails.map((trn) => (
                              <p style = {{ marginTop : '0' }}>
                                <span>
                                  <b>name: </b>{`${trn?.name || ''} ${trn?.lastName}`}
                                </span>
                                <br/>
                                <span>
                                  <b>email: </b>{trn?.email}
                                </span>
                              </p>
                              )
                            )}
                          </StyledTableCell>
                          <StyledTableCell>{row.traineeDetails.length}</StyledTableCell>
                          <StyledTableCell>{Number(row.rate * row.traineeDetails.length).toFixed(2)}</StyledTableCell>
                          <StyledTableCell>{Number((row.rate * row.traineeDetails.length) * 0.8).toFixed(2)}</StyledTableCell>
                          <StyledTableCell>{Number((row.rate * row.traineeDetails.length) * 0.2).toFixed(2)}</StyledTableCell>
                          <StyledTableCell>{row.ptDetails?.countryCode}</StyledTableCell>
                          <StyledTableCell>{row.status}</StyledTableCell>
                        </StyledTableRow>
                    ))
                  }
              </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={filteredClassList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>

    );
}

export default TraineesTableComponent;
