// Sidebar component
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "fontsource-roboto";
import React, { useContext, useState } from "react";
import { GymContext } from "../../contexts/GymContextProvider";
import { _handleDownloadCert } from "../../services/DownloadService";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ForVerificationTableGymComponent = () => {
    const { forApprovalGyms, setForApprovalGyms, pendingGyms } =
        useContext(GymContext);

    const [searchTerm, setSearchTerm] = useState("");
    const [isSelectAll, setIsSelectAll] = useState(false);

    const handleSelectAll = (event) => {
        const checked = event.target.checked;

        console.log("Is checked : " + event.target.checked);

        if (checked) {
            setForApprovalGyms(pendingGyms.map((pg) => pg.id));
            setIsSelectAll(true);
        } else {
            setForApprovalGyms([]);
            setIsSelectAll(false);
        }

        console.log(JSON.stringify(forApprovalGyms));
    };

    const handleRowClick = (event) => {
        const checked = event.target.checked;
        const id = event.target.name;
        if (checked) {
            setForApprovalGyms((thisGyms) => [...thisGyms, id]);
        } else {
            setForApprovalGyms((thisGyms) =>
                thisGyms.filter((thisId) => thisId !== id)
            );
        }
    };

    return (
        <TableContainer component={Paper}>
            <form
                style={{
                    paddingBottom: "10px",
                }}
            >
                <label> Search by name: </label>
                <input
                    icon="search"
                    placeholder="Keyword"
                    onChange={(event) => {
                        setSearchTerm(event.target.value);
                    }}
                />
            </form>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            {" "}
                            <Checkbox
                                style={{ color: "white" }}
                                onClick={handleSelectAll}
                            />{" "}
                        </StyledTableCell>
                        <StyledTableCell>NAME</StyledTableCell>
                        <StyledTableCell>AWARD/EXPERIENCES</StyledTableCell>
                        <StyledTableCell>DOWNLOADABLES</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pendingGyms
                        .filter(
                            (val) =>
                                val.name
                                    .toLowerCase()
                                    .includes(
                                        searchTerm.trim().toLowerCase()
                                    ) || searchTerm.trim() === ""
                        )
                        .map((gym) => (
                            <StyledTableRow key={gym.id}>
                                <StyledTableCell>
                                    <Checkbox
                                        checked={forApprovalGyms.includes(
                                            gym.id
                                        )}
                                        onClick={handleRowClick}
                                        name={gym.id}
                                        disabled={isSelectAll}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>{gym.name}</StyledTableCell>
                                <StyledTableCell>
                                    {gym.awardsAndExperiences}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {gym.certificates.map((cert) => (
                                        <Button
                                            onClick={() =>
                                                _handleDownloadCert(
                                                    gym.id,
                                                    cert
                                                )
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            cert
                                        </Button>
                                    ))}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ForVerificationTableGymComponent;
