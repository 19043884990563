import { createContext, useEffect, useState } from "react";
import { pendingApprovalGyms } from "../services/UsersService";

export const GymContext = createContext({});

export const GymContextProvider = (prop) => {
    const [forApprovalGyms, setForApprovalGyms] = useState([]);
    const [pendingGyms, setPendingGyms] = useState([]);

    useEffect(() => {
        (async () => {
            const gyms = await pendingApprovalGyms();
            setPendingGyms(gyms);
        })();
    }, []);

    return (
        <GymContext.Provider
            value={{
                pendingGyms,
                setPendingGyms,
                forApprovalGyms,
                setForApprovalGyms,
            }}
        >
            {prop.children}
        </GymContext.Provider>
    );
};
