import React, {useContext, useState, useEffect} from 'react'
import 'fontsource-roboto';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {UsersContext} from '../../contexts/UsersContextProvider';
import {ModalContext} from '../../contexts/ModalContext';
import { Button } from '@material-ui/core';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const PaymentsTableComponent = () => {
  const { verifiedTrainers } = useContext(UsersContext);
  const {
    setIsOpen,
    setModalData
  } = useContext(ModalContext);

  const [filteredVerifTrainersList, setFilteredVerifTrainersList] = useState(verifiedTrainers);
  const [filters, setFilters] = useState({
    filterName: '',
    filterStartDate: '',
    filterEndDate: '',
  });

  useEffect(() => {
    setFilteredVerifTrainersList(verifiedTrainers);
  }, [verifiedTrainers]);

  const onFilterByDate = (arrayToFilter) => {
    if (filters.filterStartDate && filters.filterEndDate) {
      return arrayToFilter.filter((item) => {
        return dayjs(item.subDate).isSameOrAfter(filters.filterStartDate, 'day') && dayjs(item.subDate).isSameOrBefore(filters.filterEndDate, 'day')
      });
    } else {
      return arrayToFilter
    }
  }

  const onFilterByName = (arrayToFilter) => {
    if (filters.filterName) {
      return arrayToFilter.filter((item) => item.trainee.toLowerCase().includes(filters.filterName.toLowerCase()) || item.fullName.toLowerCase().includes(filters.filterName.toLowerCase()));
    } else {
      return arrayToFilter
    }
  }

  const handleSearchFilter = (event, key) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: event }));
  }

  useEffect(() => {
    let result = verifiedTrainers;
    result = onFilterByName(result);
    result = onFilterByDate(result);
    setFilteredVerifTrainersList(result);
  }, [filters]);

  const removeFilters = () => {
    setFilters({
      filterName: '',
      filterStartDate: '',
      filterEndDate: '',
    });
    const filterForm = document.getElementById("filter-form");
    filterForm.reset();
  }

  return (
      <TableContainer component={Paper}>
          <form style = {{ paddingBottom : '10px' }}  id="filter-form">
            <div>
              <label> Search by Email: </label>
              <input
                icon='search'
                placeholder='Keyword'
                onChange={(event) => handleSearchFilter(event.target.value, "filterName")}
              />
            </div>
            <div style = {{ marginTop: '10px' }}>
              <label style = {{ whiteSpace : 'nowrap', marginRight: '10px', marginTop: '10px' }}> Filter by Date: </label>
              <div style = {{ marginTop: '10px' }}>
                <DatePicker selected={filters.filterStartDate} onChange={(date) => handleSearchFilter(date, "filterStartDate")} />
                &nbsp; to &nbsp;
                <DatePicker selected={filters.filterEndDate} onChange={(date) => handleSearchFilter(date, "filterEndDate")} />
              </div>
            </div>
          </form>
          <form style = {{ paddingBottom : '10px', display: 'flex' }}>
            <Button variant="contained" style={{ background: 'black', color: 'white' }} onClick={() => removeFilters()}>Clear Filters</Button>
          </form>
          <Table aria-label="customized table">
              <TableHead>
                  <TableRow>
                      <StyledTableCell>PT EMAIL</StyledTableCell>
                      <StyledTableCell>TRAINEE</StyledTableCell>
                      <StyledTableCell>STATUS</StyledTableCell>
                      <StyledTableCell>PAYMENT STATUS</StyledTableCell>
                      <StyledTableCell>SUBSCRIPTION ID</StyledTableCell>
                      <StyledTableCell>PT RATE</StyledTableCell>
                      <StyledTableCell>NUMBER OF SESSIONS</StyledTableCell>
                      <StyledTableCell>TOTAL INCOME</StyledTableCell>
                      <StyledTableCell>20% COMPANY PROFIT</StyledTableCell>
                      {/* <StyledTableCell>WEEKLY NET INCOME</StyledTableCell> */}
                      <StyledTableCell>ACTION</StyledTableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {verifiedTrainers ? filteredVerifTrainersList.map((session) => (
                      <StyledTableRow key={session.id}>
                        <StyledTableCell>
                            {session.fullName}
                        </StyledTableCell>
                        <StyledTableCell>{session.trainee}</StyledTableCell>
                        <StyledTableCell>{session.status}</StyledTableCell>
                        <StyledTableCell>{session.paidStatus}</StyledTableCell>
                        <StyledTableCell>{session.subscriptionId}</StyledTableCell>
                        <StyledTableCell>{session.rate}</StyledTableCell>
                        <StyledTableCell>{session.numberOfSessions}</StyledTableCell>
                        <StyledTableCell>{session.weeklyIncome}</StyledTableCell>
                        <StyledTableCell>{session.twentyPercent}</StyledTableCell>
                        {/* <StyledTableCell>{session.weeklyIncome}</StyledTableCell> */}
                        <StyledTableCell>
                        {
                          session.paidStatus ?
                            <Button variant="contained" color="primary"
                            // disabled={session.paidStatus === 'PAID' || session.enablePayBtn === 'false'}
                            disabled={session.paidStatus === 'PAID'}
                            onClick = { () => {
                              setIsOpen(true);
                              setModalData({
                                'email': session.fullName,
                                'weeklyIncome': session.weeklyIncome,
                                'numberOfSessions': session.numberOfSessions,
                                'rate': session.rate,
                                'netIncome': session.netIncome,
                                'twentyPercent': session.twentyPercent,
                                'currency': 'USD',
                                'sessionId': session.id,
                                'subscriptionId': session.subscriptionId
                            });
                            }}> Pay </Button> : '...Loading'
                          }
                        </StyledTableCell>
                      </StyledTableRow>
                  )) : ''}
              </TableBody>
          </Table>
      </TableContainer>
  );
}

export default PaymentsTableComponent;
