import React, { useContext, useEffect, useState } from 'react'
import 'fontsource-roboto'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { UsersContext } from '../../contexts/UsersContextProvider'
import { Box, FormControlLabel, FormGroup, LinearProgress } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import { NotificationsOutlined } from '@material-ui/icons'
import { db } from '../../services/Firebase'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const Div = styled.div`
    padding: 10px;
`
const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '20%',
        left: '50%',
    },
}));

const NotificationsContent = () => {

    const { loading, allUsers } = useContext(UsersContext);
    const classes = useStyles();

    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedGroups, setSelectedGroup] = useState({
        pt: false,
        trainee: false,
        gym: false
    })
    const [body, setBody] = useState('')
    const [title, setTitle] = useState('')
    const [users, setUsers] = useState([])
    const [pts, setPts] = useState([])
    const [trainees, setTrainees] = useState([])
    const [gyms, setGyms] = useState([])
    const [sending, setSending] = useState(false);

    useEffect(() => {
        if (allUsers && allUsers.length > 0) {
            const usrs = allUsers
                .filter(user => user.status === 'active').filter(user => ['pt', 'gym', 'trainee'].indexOf(user.userType) > -1)
                .map(usr => {return { ...usr, name: extractName(usr) }})
                .sort((a, b) => a.email.localeCompare(b.email))
            const grouped = Object.groupBy(usrs, ({ userType }) => userType)
            setPts(grouped.pt)
            setTrainees(grouped.trainee)
            setGyms(grouped.gym)
            setUsers([...grouped.pt, ...grouped.gym, ...grouped.trainee])
        }
    }, [allUsers])

    const extractName = (user) => {
        return [user.firstName, user.middleName, user.lastName].filter(name => name !== undefined && name !== null && name.length > 0).join(' ')
    }

    const handleChange = (event, value) => {
        setSelectedUsers(value)
        if (selectedGroups.pt) {
            if (!pts.every(pt => value.includes(pt))) {
                setSelectedGroup({ ...selectedGroups, pt: false })
            }
        }
        if (selectedGroups.gym) {
            if (!gyms.every(gym => value.includes(gym))) {
                setSelectedGroup({ ...selectedGroups, gym: false })
            }
        }
        if (selectedGroups.trainee) {
            if (!trainees.every(trainee => value.includes(trainee))) {
                setSelectedGroup({ ...selectedGroups, trainee: false })
            }
        }
    }

    const handleGroupChange = (event) => {
        setSelectedGroup({ ...selectedGroups, [event.target.name]: event.target.checked })
        if (!event.target.checked) {
            setSelectedUsers(state => state.filter(user => user.userType !== event.target.name))
        } else {
            const selections = users.filter(user => user.userType === event.target.name)
            setSelectedUsers(state => state.concat(selections.filter(item => state.indexOf(item) === -1)))
        }
    }

    const sendNotifications = async () => {
        try {
            setSending(true)
            let batchedTokens = []
            let promises = []
            for (let user = selectedUsers.pop(); ; user = selectedUsers.pop()) {
                if (!user) {
                    break
                }
                const token = user.pushToken
                if (token) {
                    batchedTokens.push(token)
                    if (batchedTokens.length === 500) {
                        promises.push(db.collection('notifications').add(
                            {
                                pushTokens: [...batchedTokens],
                                title: title,
                                body: body,
                            }
                        ))
                        batchedTokens = []
                    }
                }
            }

            if (batchedTokens.length > 0) {
                promises.push(db.collection('notifications').add(
                    {
                        pushTokens: batchedTokens,
                        title: title,
                        body: body,
                    }
                ))
            }

            await Promise.all(promises)
        } catch (err) {
            console.log(`Error: ${err}`)
        } finally {
            setSending(false)
        }
    }

    return (
        <Div>
            <Typography variant="h6" component="div">
                Notifications
            </Typography>
            <br/>
            <Box>
                {loading && <LinearProgress color="secondary"/>}
                <Autocomplete
                    multiple
                    disabled={loading}
                    id="checkboxes-tags-demo"
                    options={users}
                    size="small"
                    limitTags={1}
                    value={selectedUsers}
                    onChange={handleChange}
                    disableCloseOnSelect
                    getOptionLabel={(option) => `${option.email} (${option.name})`}
                    style={{ width: 300 }}
                    groupBy={(option) => option.userType}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Users" placeholder="Users"/>
                    )}
                />
                <FormGroup row>
                    <FormControlLabel
                        disabled={loading}
                        control={<Checkbox checked={selectedGroups.pt} onChange={handleGroupChange} name="pt"/>}
                        label="PTs"
                    />
                    <FormControlLabel
                        disabled={loading}
                        control={<Checkbox checked={selectedGroups.gym} onChange={handleGroupChange} name="gym"/>}
                        label="Gyms"
                    />
                    <FormControlLabel
                        disabled={loading}
                        control={
                            <Checkbox
                                checked={selectedGroups.trainee}
                                onChange={handleGroupChange}
                                name="trainee"
                            />
                        }
                        label="Trainees"
                    />
                </FormGroup>
            </Box>
            <Box sx={{ my: 1 }}>
                <TextField
                    size="small"
                    placeholder="Title..."
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    variant="outlined"
                />
            </Box>
            <Box sx={{ my: 1 }}>
                <TextField
                    fullWidth
                    id="outlined-textarea"
                    size="small"
                    placeholder="Body..."
                    multiline
                    variant="outlined"
                    value={body} onChange={(e) => setBody(e.target.value)}
                />
            </Box>

            <Box sx={{ mt: 1, position: 'relative', width: 215 }}>
                    <Button
                        fullWidth
                        disabled={sending || loading || body.length === 0 || title.length === 0 || selectedUsers.length === 0}
                        variant="outlined"
                        color="primary"
                        endIcon={<NotificationsOutlined/>}
                        onClick={sendNotifications}
                    >Send Notifications</Button>
                    {sending && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </Box>
        </Div>
    )
}

export default NotificationsContent