//

import color from '../font-colors/TextColor'

// This is for the component theming

const theme = {
    sidebar: {
      background: color.main.primary
    }
};

export default theme;
