import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { GymContext } from "../../contexts/GymContextProvider";
import { approveGyms, pendingApprovalGyms } from "../../services/UsersService";
import MUItheme from "../../themes/styled-components/MuiTheme";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 0, 3),
    },
    modalHeading: {
        padding: "0.5rem",
        fontWeight: "bold",
    },
    modalBody: {
        padding: "1rem",
    },
    modalButtons: {
        alignContent: "center",
        justifyContent: "center",
        margin: "auto",
        width: "100%",
    },
}));

const Ul = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-bottom: 4rem;

    li {
        display: inline-block;
    }

    .float-right {
        float: right;
    }

    .float-left {
        float: left;
    }
`;
export default function ConfirmationModalGym() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const { forApprovalGyms, setPendingGyms } = useContext(GymContext);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitToBeVerified = async () => {
        if (forApprovalGyms.length === 0) {
            setOpen(false);
            return;
        }

        await approveGyms(forApprovalGyms);
        const gyms = await pendingApprovalGyms();
        setPendingGyms(gyms);

        setOpen(false);
    };

    return (
        <div theme={MUItheme}>
            <Button
                size="large"
                style={{
                    marginTop: 12,
                    marginBottom: 12,
                    background: "black",
                }}
                variant="contained"
                color="secondary"
                type="button"
                onClick={handleOpen}
            >
                APPROVED CHECKED GYMS
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Ul>
                            <li className="float-left">
                                <Typography
                                    variant="body1"
                                    className={classes.modalHeading}
                                >
                                    APPROVED GYM
                                </Typography>
                            </li>
                            <li className="float-right">
                                <Typography
                                    variant="body1"
                                    className={classes.modalHeading}
                                >
                                    <ClearIcon onClick={handleClose} />
                                </Typography>
                            </li>
                        </Ul>
                        <hr></hr>
                        <div className={classes.modalBody}>
                            <p>
                                You are approving {forApprovalGyms.length} Gyms.
                            </p>
                            <ButtonGroup className={classes.modalButtons}>
                                <Button
                                    onClick={submitToBeVerified}
                                    size="small"
                                    style={{
                                        marginRight: 12,
                                        background: "black",
                                        borderRadius: 5,
                                        width: "67px",
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                >
                                    OK
                                </Button>

                                <Button
                                    size="small"
                                    style={{
                                        background: "#985336",
                                        borderRadius: 5,
                                        width: "67px",
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                    onClick={handleClose}
                                >
                                    CANCEL
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
