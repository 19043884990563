// Sidebar component
import React, {useContext, useState} from 'react'

import 'fontsource-roboto';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button'

import {UsersContext} from '../../contexts/UsersContextProvider';

import {_handleDownloadCV, _handleDownloadCert} from '../../services/DownloadService';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ForVerificationTableComponent = () => {
  const [state, setState] = useState({allReadyIncluded: false});
  const {notVerifiedUsers, toBeVerified, setToBeVerified} = useContext(UsersContext);
  const {allReadyIncluded} = state;
  const [searchTerm, setSearchTerm] = useState('');

  const handleIncludedUsers = (event) => {
      let userId = event.target.name;
      let isIncluded = event.target.checked;
      
      if (isIncluded === true) {
        toBeVerified.push(userId);
      }

      if (toBeVerified.includes(userId) === true && isIncluded === false) {
        toBeVerified.pop(userId);
      }

      if (toBeVerified.length !== 0) {
        setToBeVerified(toBeVerified);
      }
  }

  const handleIncludeAllUsers = (event) => {
    let isIncluded = event.target.checked;

    if (isIncluded === true) {
      notVerifiedUsers.forEach(user => {
        if (toBeVerified.includes(user.id) === true) {
          return;
        }

        toBeVerified.push(user.id);
      })

      setState({allReadyIncluded: true})

      return;
    }

    if (isIncluded === false) {
      setToBeVerified([]);
      setState({allReadyIncluded: false});
      return;
    }
}

    return (
        <TableContainer component={Paper}>
        <form style = {{
                  paddingBottom : '10px',
                }}>
          <label> Search by Lastname or Firstname: </label>
          <input 
                icon='search' 
                placeholder='Keyword' 
                onChange={(event) => {
                  setSearchTerm(event.target.value); 
                }}
            />
        </form>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell> <Checkbox 
                        style={{color: 'white'}} 
                        onClick={handleIncludeAllUsers}
                        /> </StyledTableCell>
                        <StyledTableCell>LASTNAME</StyledTableCell>
                        <StyledTableCell>FIRSTNAME</StyledTableCell>
                        <StyledTableCell>MIDDLENAME</StyledTableCell>
                        <StyledTableCell>BIRTHDAY</StyledTableCell>
                        <StyledTableCell>AWARD/EXPERIENCES</StyledTableCell>
                        <StyledTableCell>DOWNLOADABLES</StyledTableCell>    
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notVerifiedUsers.filter((val) => {
                      if (searchTerm === "") {
                        return true
                      } else if (val.firstName.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return true
                      } else if (val.lastName.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return true
                      }
                      return false;
                    }).map((row) => (
                        <StyledTableRow key={row.id}>
                        <StyledTableCell>
                          <Checkbox 
                          onClick={handleIncludedUsers} 
                          name={row.id}
                          disabled={allReadyIncluded}
                          /> 
                        </StyledTableCell>
                        <StyledTableCell>
                            {row.lastName}
                        </StyledTableCell>
                        <StyledTableCell>{row.firstName}</StyledTableCell>
                        <StyledTableCell>{row.middleName}</StyledTableCell>
                        <StyledTableCell>{row.birthday}</StyledTableCell>
                        <StyledTableCell>{row.awardsAndExperiences}</StyledTableCell>
                        <StyledTableCell>
                         <Button onClick={() => _handleDownloadCV(row.id, row.downloadablesCV)} rel="noopener noreferrer" target="_blank" >
                           {row.downloadablesCV === 'none' ? '-' : row.downloadablesCV }
                        </Button>
                         <Button onClick={() => _handleDownloadCert(row.id, row.downloadablesCV)}  rel="noopener noreferrer" target="_blank">
                           {row.downloadablesCERT === 'none' ? '-' : row.downloadablesCERT}
                          </Button>
                        </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ForVerificationTableComponent;
