import React, {useContext, useState, useEffect} from 'react'
import 'fontsource-roboto';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {UsersContext} from '../../contexts/UsersContextProvider';
import TextField from '@material-ui/core/TextField';

import { UserType, Status } from "../../utils/Constants";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import firebase from 'firebase/app';
import 'firebase/functions';
import CreatableSelect from 'react-select/creatable';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  successMessage: {
    color: theme.palette.success.main,
    marginRight: '10px'
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginRight: '10px'
  },
}));

const EmailTableComponent = () => {
  const classes = useStyles();
  const { allUsers } = useContext(UsersContext);
  const [filters, setFilters] = useState({
    filterType: '',
    filterStatus: ''
  });
  const [editorState, setEditorState] = useState();
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [subject, setSubject] = useState('')

  const sendMail = firebase.app().functions('asia-east2').httpsCallable('sendMail');

  const onFilterByStatus = (arrayToFilter) => {
    if (filters.filterStatus === 'all') {
      return arrayToFilter
    } else if (filters.filterStatus) {
      return arrayToFilter.filter((item) => item.status?.toLowerCase().includes(filters.filterStatus?.toLowerCase()));
    } else {

      return []
    }
  }

  const onFilterByType = (arrayToFilter) => {
    if (filters.filterType === 'all') {
      return arrayToFilter;
    } else if (filters.filterType) {
      return arrayToFilter.filter((item) => item.userType?.toLowerCase().includes(filters.filterType?.toLowerCase()));
    } else {
      return []
    }
  }

  useEffect(() => {
    let result = allUsers;
    result = onFilterByType(result);
    result = onFilterByStatus(result);
    if (result) {
      setEmailRecipients(() => result.map((user) => ({ value: user.email, label: user.email })))
    } else {
      setEmailRecipients([])
    }
  }, [filters]);

  const handleSearchFilter = (event, key) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: event }));
  }

  const onSendEmail = async () => {
    try {
      setErrorMessage(false);
      setSuccessMessage(false);
      setLoading(true);
      await sendMail({
        html: editorState,
        subject,
        recipient: emailRecipients.map((email) => email.value)
      });
      setSuccessMessage(true);
      setFilters({
        filterType: '',
        filterStatus: ''
      });
      setSubject('')
      setEmailRecipients(null);
      setEditorState('');
    } catch (err) {
      setSuccessMessage(false);
      setErrorMessage(true);
    } finally {
      setLoading(false);
    }
  }

  const onSelectChange = (values) => {
    setEmailRecipients(values);
  }

  const removeFilters = () => {
    setSuccessMessage(false);
    setFilters({
      filterType: '',
      filterStatus: ''
    });
    setSubject('')
    setEmailRecipients(null);
    setEditorState('');
  }

  const customCreateLabel = (inputValue) => `Add to email list "${inputValue}"`;

    return (
        <div style={{ height: '100%'}}>
          <form style = {{ paddingBottom : '10px' }} id="filter-form">
            <div>
              <label style = {{ whiteSpace : 'nowrap', marginRight: '10px' }}> Filter By User Type: </label>
              <select
                defaultValue=""
                onChange={(event) => handleSearchFilter(event.target.value, "filterType")}
              >
                {UserType && UserType.map((type) =>
                    <option key={type.code} value={type.code}>{type.name}</option>
                )}
              </select>
            </div>
            <div style = {{ marginTop: '10px', marginBottom: '10px' }}>
              <label style = {{ whiteSpace : 'nowrap', marginRight: '10px' }}> Filter By Status: </label>
              <select
                defaultValue=""
                onChange={(event) => handleSearchFilter(event.target.value, "filterStatus")}
              >
                {Status && Status.map((status) =>
                    <option key={status.code} value={status.code}>{status.name}</option>
                )}
              </select>
            </div>
            <div>
              <CreatableSelect
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                isMulti
                value={emailRecipients}
                menuPortalTarget={document.body}
                options={allUsers.map((user) => ({ value: user.email, label: user.email }))}
                onChange={onSelectChange}
                placeholder="Send to"
                allowCreate
                formatCreateLabel={customCreateLabel}
              />
            </div>
            <div>
               <TextField
                  label="Subject"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                  fullWidth
                  margin="normal"
                />
            </div>
            <ReactQuill theme="snow" value={editorState} onChange={setEditorState} />
            <div style = {{ paddingTop : '10px', display: 'flex', justifyContent: 'space-between',  }}>
              <>
                <Button variant="text" style={{ color: 'blue' }} onClick={removeFilters}>Clear</Button>
              </>
              <div style = {{ paddingTop : '10px', display: 'flex', justifyContent: 'flex-end',  }}>
                {errorMessage && <p className={classes.errorMessage}>Error sending message</p>}
                {successMessage && <p className={classes.successMessage}> Success! Your message has been sent. </p>}
                {isLoading ? <CircularProgress /> : <Button variant="contained" style={{ background: 'black', color: 'white' }} onClick={() => onSendEmail()}>Send Email</Button>}
              </div>
            </div>
          </form>
        </div>
    );
}

export default EmailTableComponent;
