// Dashboard Page

import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import styled from "styled-components";
import ForVerificationGymContent from "../components/dashboard/ForVerificationGym";
import MobileNav from "../components/navbar/MobileNavbar";
import Sidebar from "../components/navbar/Sidebar";
import media from "../themes/media-query/Device";
import theme from "../themes/styled-components/MuiTheme";
import "../themes/styles/App.css";

const Mobile = styled.div`
    display: block;
    margin-bottom: 2rem;

    @media ${media.tablet} {
        display: none;
    }

    @media ${media.desktop} {
        display: none;
    }
`;

// Dashboard

const ForVerificationGym = () => {
    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Sidebar />
                    <Mobile>
                        <MobileNav />
                    </Mobile>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <ForVerificationGymContent />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default ForVerificationGym;
