// Sidebar component
import React from 'react'
import 'fontsource-roboto';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography'
import TableComponent from '../table/Table'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import FeedbackIcon from '@material-ui/icons/Feedback';

const Div = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
`;

const DashboardContentComponent = () => {
    return (
        <Div>
            <Typography variant="h6" component="div">
                PERSONAL TRAINER - LIST
            </Typography>
            <Div>
                <CheckCircleIcon/><Typography component="span" style={{verticalAlign: 'super', paddingRight: '1em'}}>PT is Verified</Typography>
                <BlockIcon/> <Typography component="span" style={{verticalAlign: 'super', paddingRight: '1em'}}>PT is Disabled</Typography>
                <FeedbackIcon/> <Typography  component="span" style={{verticalAlign: 'super',paddingRight: '1em'}}>PT is For Approval</Typography>
            </Div>
            <TableComponent/>
        </Div>    
    );
}

export default DashboardContentComponent;