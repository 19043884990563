import React, { useState } from 'react'
import '../themes/styles/App.css';
import logo from '../images/logo.png';
import styled from "styled-components";

import media from '../themes/media-query/Device'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";

import {handleSignUp} from '../services/UsersService';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const LoginForm = styled.div`
    background-color: white;
    width: 100%;

    img {
        width: 100%;
    }

    .userEmail, .userPassword {
        margin-bottom: 1rem;
        width: 80%
    }

    .loginLabel {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .loginButton {
        width: 80%;
        margin-bottom: 4rem;
    }

    .loginButton:disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
    

    @media ${media.desktop} {
        width: 30%;

        .userEmail, .userPassword {
            width: 70%
        }

        .loginButton {
            width: 70%;
        }
    }

    a {
        text-decoration: none !important;
    }
`;

export default function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [success] = React.useState(false);
    
    const history = useHistory();

    const routeChange = (route) =>{ 
        history.push(route);
    }

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    }

    const validateConfirmPassword = () => {
        return confirmPassword === password;
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (validateForm() === false) {
            setError("Fields are required.");
            setOpen(true);
            return;
        }

        
        if (validateConfirmPassword() === false) {
            setError("Passwords are not matched.");
            setOpen(true);
            return;
        }

        setOpen(false);

        handleSignUp(email, password).then(response => {
            if (response.user !== undefined && response.operationType !== undefined) {
                setIsSuccess(true);

                setOpen(false);
                setTimeout(5000, routeChange('/'))

                return;
            }
            
            setError(response.message);
            setOpen(true);
        });
    }

    return (
        <div className="App">
        <header className="App-header">
            <LoginForm>
                <img src = { logo } alt = "login-logo"></img>
                <Typography variant = "h5" color="primary" className = "loginLabel"> SIGN UP </Typography>

                <Snackbar open={isSuccess} autoHideDuration={3000}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity="success">
                    {success}
                    </Alert>
                </Snackbar>

                <Snackbar open={open} autoHideDuration={3000}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity="warning">
                    {error}
                    </Alert>
                </Snackbar>
                
                   
                <TextField
                    variant="outlined" 
                    color="secondary"
                    type="email"
                    label="email"
                    className="userEmail"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                     required
                />
                <TextField
                    variant="outlined" 
                    color="secondary"
                    type="password"
                    label="password"
                    className="userPassword"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />

                <TextField
                    variant="outlined" 
                    color="secondary"
                    type="password"
                    label="confirm password"
                    className="userPassword"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <Button
                    size = "large"
                    variant = "contained" 
                    color = "primary"
                    type="submit"
                    className = "loginButton"
                    onClick={handleSubmit}
                >
                    SIGN UP
                </Button>
            </LoginForm>

        </header>
    </div>
    )
}
